import * as types from '../../actions/course/types'

const defaultState = {
  isLoading: false,
  data: {},
  error: null,
  success: false
}

const loadCourse = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successCourse = (state, payload) => ({
  ...state,
  data: payload.data,
  error: null,
  isLoading: false,
  success: true
})

const failCourse = (state, payload) => ({
  ...state,
  data: {},
  error: payload.error,
  isLoading: false,
  success: false
})

const clearCourse = () => ({
  ...defaultState
})

const registerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.COURSE_GET_LOAD:
      return loadCourse(state)

    case types.COURSE_GET_SUCCESS:
      return successCourse(state, action.payload)

    case types.COURSE_GET_FAILED:
      return failCourse(state, action.payload)

    case types.COURSE_GET_CLEAR:
      return clearCourse()

    default:
      return state
  }
}

export default registerReducer
