import { all } from 'redux-saga/effects'

import applicationListSaga from './applicationList/saga'
import applicationSaga from './application/saga'
import assessmentSaga from './assessment/saga'
import authorizationSaga from './authorization/saga'
import courseSaga from './course/saga'
import formSaga from './form/saga'
import jobSaga from './job/saga'
import jobDetailSaga from './jobDetail/saga'
import jobListSaga from './jobList/saga'
import loginSaga from './login/saga'
import passwordChangeSaga from './passwordChange/saga'
import passwordRecoverSaga from './passwordRecover/saga'
import passwordResetSaga from './passwordReset/saga'
import registerSaga from './register/saga'
import userDataSaga from './userData/saga'
import companySaga from './company/saga'
import couponSaga from './coupon/saga'
import gatekeeperSaga from './gatekeeper/saga'

function * rootSaga () {
  yield all([
    applicationListSaga(),
    applicationSaga(),
    assessmentSaga(),
    authorizationSaga(),
    couponSaga(),
    courseSaga(),
    formSaga(),
    jobSaga(),
    jobDetailSaga(),
    jobListSaga(),
    loginSaga(),
    passwordChangeSaga(),
    passwordRecoverSaga(),
    passwordResetSaga(),
    registerSaga(),
    userDataSaga(),
    companySaga(),
    gatekeeperSaga()
  ])
}

export default rootSaga
