import React, { Component } from 'react'
import { Trans } from '@lingui/macro'
import {
  TypographyEduk,
  themes,
  ModuleCardEduk,
  BannerImageCardEduk,
  ButtonEduk
} from '@mindlab-org/component-library'
import Plural from '../../components/Pural'
import './ApplicationModules.sass'
import { routesEduk } from '../../routes/routesEduk'

class AbstractApplicationContent extends Component {
  state = {
    currentStep: {
      title: null,
      description: null,
      status: null
    }
  }

  /**
   *
   * @returns renderiza conteúdo dos Módulos da Aplicação.
   */
  renderModules = () => {
    return (
      <div className="ApplicationModules__Modules__Required ApplicationModules__Modules__List">
        <div className="ApplicationModules__Modules__List__Title">
          <TypographyEduk
            type={'h3'}
            fontSize={'14px'}
            fontWeight={'700'}
            lineHeight={'18px'}
            color={themes.eduk.colors.lateBlack}>
            <Plural
              singular={(<Trans id="Preechimento necessário" />)}
              plural={(<Trans id="Preenchimentos necessários" />)}
              value={this.props.modules.modules.length} />
          </TypographyEduk>
        </div>
        <div className="ApplicationModules__Modules__List__Cards">
          {
            this.props.modules.allModules.map(mod => {
              const url = `${mod.redirectUrl}?application_id=${this.props.applicationId}&jobRoleSlug=${this.props.jobRoleSlug}`
              return (
                <div className="ApplicationModules__Modules__List__Cards__Block"
                  key={mod.module.id}>
                  <div className="ApplicationModules__Modules__List__Cards__Block__Card">
                    <ModuleCardEduk
                      description={mod.module.description}
                      iconName={mod.cardConfigurations.iconName}
                      lineHeight='16px'
                      iconBackgroundColor={mod.cardConfigurations.iconBackgroundColor}
                      onClickCard={mod.isEditable && !mod.isCompleted ? () => this.props.history.push(url) : null}
                      title={mod.module.title}
                      time={mod.module.time}
                      titleSize='14px'
                      iconCircleSize='35px'
                      cardPadding='14px 0px'
                    />
                  </div>
                  {
                    mod.hasBlockedMessages && (
                      <div className="ApplicationModules__Modules__List__Cards__Block__Error">
                        {this.buildModuleListErrorDescription(mod.module.blockedMessages)}
                      </div>
                    )
                  }
                </div>
              )
            })
          }
        </div>
      </div>)
  }

  renderGatekeeperFeedback = () => {
    return (
      <>
        <div className="ApplicationModules__Message">
          <div className="ApplicationModules__Message__Wrapper">
            <p>Infelizmente, neste momento, você não atende aos requisitos mínimos para esta oportunidade.</p><br />
            <p>Por isso, estamos finalizando o seu processo com essa empresa.</p>
          </div>
        </div>
        <div className="ApplicationModules">
          <div className="GateKeeper">
            <h2>Que tal conferir outras oportunidades disponíveis na Eduk | Gerando Renda?</h2>
          </div>
          <a href={routesEduk.jobList } target='_blank' rel="noreferrer" className="ApplicationModules__Message__Button" >
            <ButtonEduk retangular letterSpacing={'1px'}>Ver oportunidades</ButtonEduk>
          </a>
        </div>
      </>
    )
  }

  /**
   * @description Renderiza descrição do erro no respectivo módulo
   * @param {} blockedMessages
   * @returns conteúdo que deve ser renderizado
   */
  buildModuleListErrorDescription = (blockedMessages) => {
    return blockedMessages.map((message, index) => {
      if (message.isCourse) {
        const redirect = message.url.includes('http')
        const handleClick = () => redirect ? window.open(message.url) : this.props.courseSet({ slug: message.url })
        return (
          <>
            <span>{message.text}</span>
            <a onClick={() => handleClick()}>Acessar curso</a>
          </>
        )
      }

      return <span key={index}> {message.text} </span>
    })
  }

  /**
   * @description Renderiza Banner e Modal.
   * @returns conteúdo que deve ser renderizado
   */
  renderBanner = () => {
    return (
      <div className="ApplicationModules__Header">
        <div className="ApplicationModules__Header__BannerImage">
          <BannerImageCardEduk
            title={this.props.application.application.data.application.jobRole.title}
            subtitle={' '}
            imageUrl={this.props.application.application.data.application.jobRole.imageUrl}
          />
        </div>
        <div className="ApplicationModules__Header__Application">
          <div className="ApplicationModules__Header__Application__Block">
            <TypographyEduk
              color={themes.eduk.colors.lateBlack}
              fontSize="24px"
              fontWeight="bolder">
              <Trans id="Candidatura" />
            </TypographyEduk>
          </div>
        </div>
        <div className="ApplicationModules__Header__StepDescription">
          <div className="ApplicationModules__Header__StepDescription__Description">
            <TypographyEduk>
              {this.state.currentStep.description}
            </TypographyEduk>
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <>
        {this.renderModules()}
      </>
    )
  }
}

export default (AbstractApplicationContent)
