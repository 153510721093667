import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import * as routes from '../routes'

const UnauthorizedRoute = props => {
  return props.authorizationData.isAuth
    ? <Redirect to={routes.HOMEPAGE}/>
    : <Route {...props}/>
}

const mapStateToProps = state => ({
  authorizationData: state.authorization
})

export default connect(mapStateToProps)(UnauthorizedRoute)
