import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { HeaderEduk, ButtonEduk, themes } from '@mindlab-org/component-library'

import * as routes from '../../routes/routes'
import { actions } from '../../redux/actions/actions'
import clearSiteData from '../../utils/Authentication/clearSiteData'
import { routesEduk } from '../../routes/routesEduk'
import { menuItems } from './menuItems'

export const CustomHeader = ({
  authorizationUnset,
  history,
  isAuth,
  goBack,
  routeGoBack,
  blockMenu
}) => {
  const onClickLogo = () => window.open(routes.HEADER_EDUK_LINK)
  const onClickGoBack = () => routeGoBack ? history.push(routeGoBack) : history.push(routes.ONBOARDING)

  const onClickLogout = () => {
    authorizationUnset()
    clearSiteData()
  }

  const menuConfig = {
    menuItems: menuItems({ onClickLogout, isAuth }),
    menuButtons: isAuth ? [] : [
      <ButtonEduk
        key={1}
        retangular
        backgroundColor={themes.eduk.colors.primaryColor}
        onButtonClick={() => window.open(routesEduk.register, '_self')}
        hoverColor={'#d9002b'}>
          Criar conta
      </ButtonEduk>,
      <ButtonEduk
        key={2}
        retangular
        backgroundColor={'transparent'}
        onButtonClick={() => window.open(routesEduk.login, '_self')}
        hoverColor={'transparent'}
        textColor={themes.eduk.colors.primaryColor}
        borderColor={themes.eduk.colors.primaryColor}>
          Entrar
      </ButtonEduk>],
    menuTems: null
  }

  const hiddenLogo = () => {
    const path = window.location.pathname
    return (path === '/entrar')
  }

  return (
    <HeaderEduk
      {...menuConfig}
      {...{ goBack, onClickGoBack, onClickLogo, blockMenu, blockLogo: hiddenLogo() }}
    />
  )
}

CustomHeader.propTypes = {
  authorizationUnset: PropTypes.func,
  blockMenu: PropTypes.bool,
  history: PropTypes.any,
  isAuth: PropTypes.bool
}

CustomHeader.defaultProps = {
  isAuth: false,
  blockActions: false
}

const mapStateToProps = state => ({
  isAuth: state.authorization.isAuth
})

const mapDispatchToProps = dispatch => ({
  authorizationUnset: () => dispatch(actions.authorizationUnset())
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomHeader)
