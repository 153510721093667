import * as types from '../../actions/passwordChange/types'

const defaultState = {
  isLoading: false,
  error: null,
  success: false
}

const loadPasswordChange = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successPasswordChange = (state) => ({
  ...state,
  error: null,
  isLoading: false,
  success: true
})

const failPasswordChange = (state, payload) => ({
  ...state,
  error: payload.error,
  isLoading: false,
  success: false
})

const clearPasswordChange = () => ({
  ...defaultState
})

const passwordChangeReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.PASSWORD_CHANGE_LOAD:
      return loadPasswordChange(state)

    case types.PASSWORD_CHANGE_SUCCESS:
      return successPasswordChange(state)

    case types.PASSWORD_CHANGE_FAILED:
      return failPasswordChange(state, action.payload)

    case types.PASSWORD_CHANGE_CLEAR:
      return clearPasswordChange()

    default:
      return state
  }
}

export default passwordChangeReducer
