import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import ApplicationModules from '../views/ApplicationModules'
import Assessment, { AssessmentScore } from '../views/Assessment'
import Forms from '../views/Forms'
import JobDetail from '../views/JobDetail'
import Coupons from '../views/Coupons'
import AuthorizedRoute from './Routers/AuthorizedRoute'
import UnauthorizedRoute from './Routers/UnauthorizedRoute'
import * as routes from './routes'
import { routesEduk } from './routesEduk'
import CompanyJobJames from '../views/CompanyJob/CompanyJobJames'

function AppRouter () {
  const url = document.location.hostname
  if (url.includes('vojo')) {
    return (window.location.href = routes.RENDA_EDUK)
  }

  const redirect = (route) => {
    window.location = route
  }

  return (
    <Router>
      <Switch>
        <Route exact path={'/company/james-delivery'} component={CompanyJobJames} />
        <Route exact path={routes.HOMEPAGE} component={(() => <Redirect to={routes.JOBS_LIST}/>)} />
        <Route path={routes.COURSES} component={() => redirect(routesEduk.home)}/>
        <Route exact path={routes.JOBS_LIST} component={() => redirect(routesEduk.jobList)}/>
        <Route path={routes.JOB_DETAIL()} component={JobDetail}/>
        <Route path={routes.RECOVER_PASSWORD} component={() => redirect(routesEduk.changePassword)}/>
        <UnauthorizedRoute path={routes.FORGOT_PASSWORD} component={() => redirect(routesEduk.forgotPassword)}/>
        <Route path={routes.REGISTER} component={() => redirect(routesEduk.register)}/>
        <Route path={routes.LOGIN} component={() => redirect(routesEduk.login)}/>
        <AuthorizedRoute exact path={routes.APPLICATION()} component={ApplicationModules}/>
        <AuthorizedRoute path={routes.MY_APPLICATIONS} component={() => redirect(routesEduk.jobList)}/>
        <AuthorizedRoute exact path={routes.FORMS()} component={Forms}/>
        <AuthorizedRoute exact path={routes.ASSESSMENT()} component={Assessment}/>
        <AuthorizedRoute exact path={routes.ASSESSMENT_SCORE} component={AssessmentScore}/>
        <AuthorizedRoute path={routes.MY_ACCOUNT} component={() => redirect(routesEduk.personal)} />
        <AuthorizedRoute exact path={routes.COUPONS} component={Coupons} />
        {
          /**
           * As seguintes rotas se referem às rotas do cliente legado
          */
        }
        <Route exact path="/caminho" component={(() => <Redirect to={routes.JOBS_LIST}/>)} />
        <Route path="/application" component={() => redirect(routesEduk.jobList)}/>
        <Route path="/jobs" component={() => redirect(routesEduk.jobList)}/>
        <Route path="/login" component={() => redirect(routesEduk.login)}/>
        <Route path="/register" component={() => redirect(routesEduk.register)}/>
        <Route path="/reset-password" component={() => redirect(routesEduk.forgotPassword)}/>
      </Switch>
    </Router>
  )
}

export default AppRouter
