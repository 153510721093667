import * as types from './types'

export const authorizationLoad = () => ({
  type: types.AUTHORIZATION_LOAD
})

export const authorizationSet = () => ({
  type: types.AUTHORIZATION_SET
})

export const authorizationUnset = () => ({
  type: types.AUTHORIZATION_UNSET
})

export const authorizationUnsetSuccess = () => ({
  type: types.AUTHORIZATION_UNSET_SUCCESS
})

export const authorizationError = error => ({
  type: types.AUTHORIZATION_FAILED,
  error
})

export const authorizationSuccess = () => ({
  type: types.AUTHORIZATION_SUCCESS
})
