import * as types from '../../actions/coupon/types'

const defaultState = {
  isLoading: false,
  data: [{
    id: undefined
  }],
  error: null,
  success: false,
  showModal: false
}

const loadCoupon = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successCoupon = (state, payload) => ({
  ...state,
  data: payload.length ? payload : defaultState.data,
  error: null,
  isLoading: false,
  success: true
})

const validateCoupon = (state, payload) => ({
  ...state,
  code: payload,
  error: null,
  isLoading: true,
  success: false
})

const validateCouponSuccess = (state, payload) => ({
  ...state,
  code: payload,
  error: null,
  isLoading: false,
  success: true,
  showModal: true
})

const failCoupon = (state, payload) => ({
  ...state,
  error: payload.error,
  isLoading: false,
  success: false
})

const closeFailButton = (state) => ({
  ...state,
  error: null
})

const resetSuccess = (state) => ({
  ...state,
  showModal: false
})

const clearCoupon = () => ({
  ...defaultState
})

const registerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.COUPON_GET:
      return loadCoupon(state)

    case types.COUPON_GET_SUCCESS:
      return successCoupon(state, action.payload)

    case types.COUPON_GET_FAILED:
      return failCoupon(state, action.error)

    case types.COUPON_VALIDATE_GET:
      return validateCoupon(state, action.data)

    case types.COUPON_VALIDATE_SUCCESS:
      return validateCouponSuccess(state, action.data)

    case types.COUPON_VALIDATE_FAILED:
      return failCoupon(state, action.payload)

    case types.COUPON_CLOSE_ERROR:
      return closeFailButton(state)

    case types.COUPON_GET_CLEAR:
      return clearCoupon()

    case types.COUPON_RESET_SUCCESS:
      return resetSuccess(state)

    default:
      return state
  }
}

export default registerReducer
