export const mappedUtm = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content'
]

export const setUtmToStorage = () => {
  if (window.location.search) {
    const builtUrl = new URL(window.location.href)
    const UTM = {}

    mappedUtm.map(currentUtm => {
      const utmFromUrl = builtUrl.searchParams.get(currentUtm)
      if (utmFromUrl) {
        UTM[currentUtm.replace('utm_', '')] = utmFromUrl
      }
      return currentUtm // default return
    })

    sessionStorage.setItem('UTM', JSON.stringify(UTM))
  }
}

export const getUtmFromStorage = () => {
  const UTM = sessionStorage.getItem('UTM')

  if (!UTM) {
    return (null)
  }

  return JSON.parse(UTM)
}
