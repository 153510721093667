export const COUPON_GET = 'COUPON_GET'
export const COUPON_GET_LOAD = 'COUPON_GET_LOAD'
export const COUPON_GET_SUCCESS = 'COUPON_GET_SUCCESS'
export const COUPON_GET_FAILED = 'COUPON_GET_FAILED'

export const COUPON_VALIDATE_GET = 'COUPON_VALIDATE_GET'
export const COUPON_VALIDATE_LOAD = 'COUPON_VALIDATE_LOAD'
export const COUPON_VALIDATE_SUCCESS = 'COUPON_VALIDATE_SUCCESS'
export const COUPON_VALIDATE_FAILED = 'COUPON_VALIDATE_FAILED'

export const COUPON_CLOSE_ERROR = 'COUPON_CLOSE_ERROR'
export const COUPON_GET_CLEAR = 'COUPON_GET_CLEAR'
export const COUPON_RESET_SUCCESS = 'COUPON_RESET_SUCCESS'
