import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { loginUser } from './requests'

export function * loginSet (action) {
  try {
    yield put({ type: types.LOGIN_LOAD })
    yield call(loginUser, action.data)
    yield put({ type: types.LOGIN_SUCCESS })
    yield put({ type: types.AUTHORIZATION_SET })
  } catch (e) {
    yield put({ type: types.LOGIN_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * loginSaga () {
  yield takeLatest(types.LOGIN_SET, loginSet)
}
