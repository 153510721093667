import * as types from './types'

export const passwordResetLoad = () => ({
  type: types.PASSWORD_RESET_LOAD
})

export const passwordResetSet = data => ({
  type: types.PASSWORD_RESET_SET,
  data
})

export const passwordResetError = error => ({
  type: types.PASSWORD_RESET_FAILED,
  error
})

export const passwordResetSuccess = data => ({
  type: types.PASSWORD_RESET_SUCCESS,
  data
})

export const passwordResetClear = () => ({
  type: types.PASSWORD_RESET_CLEAR
})
