/**
 * @description verifica a propriedade isValid
 * @param {string} field - dados do campo
 * @returns **true** se o valor é inválido, e **false** caso válido
*/
const isInvalid = (field) => {
  return field.isValid === undefined ? false : !field.isValid
}

/**
 * @description verifica os campos inválidos
 * @param {object} fields
 * @returns {string[]} os nomes dos campos que não estão válidos
*/
export const checkValidationFields = (fields) => {
  return Object.entries(fields)
    .filter(([_key, field]) => isInvalid(field))
    .map(([fieldName]) => fieldName)
}
