import * as types from './types'

export const passwordChangeLoad = () => ({
  type: types.PASSWORD_CHANGE_LOAD
})

export const passwordChangeSet = data => ({
  type: types.PASSWORD_CHANGE_SET,
  data
})

export const passwordChangeError = error => ({
  type: types.PASSWORD_CHANGE_FAILED,
  error
})

export const passwordChangeSuccess = () => ({
  type: types.PASSWORD_CHANGE_SUCCESS
})

export const passwordChangeClear = () => ({
  type: types.PASSWORD_CHANGE_CLEAR
})
