import * as types from './types'

export const gatekeeperGet = data => ({
  type: types.GATEKEEPER_GET,
  data
})

export const gatekeeperGetLoad = () => ({
  type: types.GATEKEEPER_LOAD
})

export const gatekeeperGetSuccess = () => ({
  type: types.GATEKEEPER_SUCCESS
})

export const gatekeeperResult = data => ({
  type: types.GATEKEEPER_RESULT,
  data
})

export const gatekeeperGetError = error => ({
  type: types.GATEKEEPER_ERROR,
  error
})

export const gatekeeperClearResult = () => ({
  type: types.GATEKEEPER_CLEAR
})
