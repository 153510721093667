import React, { Component } from 'react'
import { connect } from 'react-redux'
import QueryString from 'qs'
import {
  Container,
  FooterEduk,
  TypographyEduk,
  SpinnerEduk,
  StickyToast,
  themes
} from '@mindlab-org/component-library'
import * as typeformEmbed from '@typeform/embed'

import './Assessment.sass'
import * as routes from '../../routes/routes'
import CustomHeader from '../../components/CustomHeader'
import { actions } from '../../redux/actions/actions'
import Sentry from '../../services/Sentry'
class Assessment extends Component {
  state = {
    applicationId: QueryString.parse(this.props.location.search)['?application_id'],
    sentryActive: false
  }

  sentry = new Sentry()
  embedElementId = 'form-builder'

  componentDidMount () {
    this.getAssessmentData()
    this.isInvalidId(this.state.applicationId) && this.handleUndefinedId()
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.assessmentData.fetchedAssessment.success !== this.props.assessmentData.fetchedAssessment.success &&
      this.props.assessmentData.fetchedAssessment.success
    ) {
      this.state.sentryActive && this.dispatchSentry()
      this.setEmbeddedTypeForm()
    }
  }

  /**
   * @param {undefined | null | string} id
   * @description Verifica se o Id é inválido
   * @returns **true** caso inválido, **false** do contrário
   */
  isInvalidId = (id) => {
    return [null, 'null', undefined, 'undefined', '', ':id']
      .includes(id)
  }

  /**
   * @description verifica que há no session storage o applicationId
   * se existe, salva no estado o id.
   * do contrário, redireciona para a página de candidaturas
   */
  handleUndefinedId = () => {
    this.setState({ sentryActive: true })
    const applicationId = sessionStorage.getItem('applicationId')

    if (applicationId) return this.setState({ applicationId: applicationId })

    this.dispatchSentry()
    this.props.history.push(routes.MY_APPLICATIONS)
  }

  /**
   * @description dispara evento do sentry
   */
  dispatchSentry = () => {
    const data = {
      applicationId: this.state.applicationId,
      setContext: {
        state: this.state,
        reduxAssessment: this.props.assessmentData.fetchedAssessment.data
      }
    }
    this.sentry.sentryEvent('undefinedId', data)
  }

  getAssessmentData = () => {
    const { id } = this.props.match.params
    this.props.assessmentGet({ id })
  }

  setEmbeddedTypeForm = () => {
    const embedElement = document.querySelector(`#${this.embedElementId}`)
    const typeformUrl = this.buildTypeFormUrl()
    typeformEmbed.makeWidget(embedElement, typeformUrl)
  }

  buildTypeFormUrl = () => {
    const { applicationId } = this.state
    const { id } = this.props.match.params
    const { formId } = this.props.assessmentData.fetchedAssessment.data
    const userId = this.props.authorization.data.id
    const env = process.env.REACT_APP_VOJO_URL
    const urlQueryParameters = `?form_id=${formId}&assessment_id=${id}&application_id=${applicationId}&user_id=${userId}&env=${env}`
    return `${process.env.REACT_APP_TYPEFORM_FORM_URL}/${formId}${urlQueryParameters}`
  }

  render () {
    return (
      <Container maxWidth="full">
        <Container maxWidth="full">
          <CustomHeader history={this.props.history} />
          {
            this.props.assessmentData.fetchedAssessment.error && (
              <div style={{
                display: 'block',
                width: '100%',
                zIndex: '100'
              }}>
                <StickyToast show>
                  <TypographyEduk
                    color={themes.eduk.colors.redColor}
                    tag="span"
                    type="default">
                    {this.props.assessmentData.fetchedAssessment.error.message}
                  </TypographyEduk>
                </StickyToast>
              </div>
            )
          }
        </Container>
        <Container maxWidth="desktop">
          {
            this.props.assessmentData.fetchedAssessment.loading && (
              <SpinnerEduk spinnerArea="80vh" />
            )
          }
          {
            this.props.assessmentData.fetchedAssessment.success && (
              <div id={this.embedElementId}
                style={{
                  height: '90vh',
                  width: '100%'
                }}
              />
            )
          }
        </Container>
        <Container maxWidth="full">
          <FooterEduk />
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  assessmentData: state.assessment,
  authorization: state.authorization
})

const mapDispatchToProps = dispatch => ({
  assessmentGet: data => dispatch(actions.assessmentGet(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Assessment)
