import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers/rootReducer'
import rootSaga from './sagas/rootSaga'

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  let store
  if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    store = createStore(
      rootReducer,
      compose(
        applyMiddleware(sagaMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      )
    )
  } else {
    store = createStore(
      rootReducer,
      applyMiddleware(sagaMiddleware)
    )
  }

  sagaMiddleware.run(rootSaga)

  return store
}

export default configureStore
