import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { getGatekeeper } from './requests'

export function * gatekeeperGet (action) {
  try {
    yield put({ type: types.GATEKEEPER_LOAD })
    const gatekeeper = yield call(getGatekeeper, action.data)
    yield put({ type: types.GATEKEEPER_SUCCESS, payload: { data: gatekeeper } })
  } catch (e) {
    yield put({ type: types.GATEKEEPER_ERROR, payload: { error: e } })
  }
}

export default function * gatekeeperSaga () {
  yield takeLatest(types.GATEKEEPER_GET, gatekeeperGet)
}
