import * as types from '../../actions/company/types'

const defaultState = {
  data: null,
  isLoading: false,
  error: null,
  success: false,
  user: {
    isLoading: false,
    error: null,
    success: false
  }
}

const getCompany = (state) => ({
  ...defaultState,
  ...state,
  isLoading: false
})

export const setCompanyLoad = (state) => ({
  ...state,
  isLoading: true
})

export const setCompanySuccess = (state, payload) => ({
  ...state,
  data: payload,
  success: true,
  error: null,
  isLoading: false
})

export const clearCompany = () => ({
  ...defaultState
})

export const setCompanyFailed = (state, payload) => ({
  ...state,
  error: payload.error,
  success: false,
  isLoading: false
})

export const addUserLoad = (state) => ({
  ...state,
  user: {
    ...state.user,
    isLoading: true
  }
})

export const addUserSuccess = (state, payload) => ({
  ...state,
  user: {
    data: payload.data,
    success: true,
    error: null,
    isLoading: false
  }
})

export const addUserFailed = (state, payload) => ({
  ...state,
  user: {
    ...state.user,
    error: payload.error,
    success: false,
    isLoading: false
  }
})

const companyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.COMPANY_GET:
      return getCompany(state)
    case types.COMPANY_SET_LOAD:
      return setCompanyLoad(state)
    case types.COMPANY_SET_SUCCESS:
      return setCompanySuccess(state, action.payload)
    case types.COMPANY_SET_FAILED:
      return setCompanyFailed(state, action.payload)
    case types.COMPANY_CLEAR:
      return clearCompany(state)
    case types.ADD_USER_LOAD:
      return addUserLoad(state)
    case types.ADD_USER_SUCCESS:
      return addUserSuccess(state, action.payload)
    case types.ADD_USER_FAILED:
      return addUserFailed(state, action.payload)
    default:
      return state
  }
}

export default companyReducer
