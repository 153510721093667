export const APPLICATION_SUBMIT_SET = 'APPLICATION_SUBMIT_SET'
export const APPLICATION_SUBMIT_LOAD = 'APPLICATION_SUBMIT_LOAD'
export const APPLICATION_SUBMIT_SUCCESS = 'APPLICATION_SUBMIT_SUCCESS'
export const APPLICATION_SUBMIT_FAILED = 'APPLICATION_SUBMIT_FAILED'
export const APPLICATION_SUBMIT_CLEAR = 'APPLICATION_SUBMIT_CLEAR'

export const APPLICATION_UPDATE_SET = 'APPLICATION_UPDATE_SET'
export const APPLICATION_UPDATE_LOAD = 'APPLICATION_UPDATE_LOAD'
export const APPLICATION_UPDATE_SUCCESS = 'APPLICATION_UPDATE_SUCCESS'
export const APPLICATION_UPDATE_FAILED = 'APPLICATION_UPDATE_FAILED'
export const APPLICATION_UPDATE_CLEAR = 'APPLICATION_UPDATE_CLEAR'

export const APPLICATION_CHECK_SET = 'APPLICATION_CHECK_SET'
export const APPLICATION_CHECK_LOAD = 'APPLICATION_CHECK_LOAD'
export const APPLICATION_CHECK_SUCCESS = 'APPLICATION_CHECK_SUCCESS'
export const APPLICATION_CHECK_FAILED = 'APPLICATION_CHECK_FAILED'
export const APPLICATION_CHECK_CLEAR = 'APPLICATION_CHECK_CLEAR'

export const APPLICATION_CLEAR = 'APPLICATION_CLEAR'
export const APPLICATION_DATA_CLEAR = 'APPLICATION_DATA_CLEAR'
