import React from 'react'
import PropTypes from 'prop-types'

const Plural = ({
  singular,
  plural,
  value
}) => {
  const isPlural = Boolean(value > 1)

  if (isPlural) {
    return (<>{plural}</>)
  }

  return (<>{singular}</>)
}

Plural.propTypes = {
  singular: PropTypes.node,
  plural: PropTypes.node,
  value: PropTypes.number
}

export default Plural
