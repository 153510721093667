import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const getGatekeeper = async (data) => {
  try {
    const response = await VojoAxios.get(apiEndpoints.GET_GATEKEEPERS(data.applicationId))
    return response.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
