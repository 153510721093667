import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { getUserData, setUserData } from './requests'

export function * userDataGet () {
  try {
    yield put({ type: types.USER_DATA_GET_LOAD })
    const userData = yield call(getUserData)
    yield put({ type: types.USER_DATA_GET_SUCCESS, payload: userData })
  } catch (error) {
    yield put({ type: types.USER_DATA_GET_FAILED }, { error })
  }
}

export function * userDataSet (action) {
  try {
    yield put({ type: types.USER_DATA_SET_LOAD })
    const userData = yield call(setUserData, action.payload)
    yield put({ type: types.USER_DATA_SET_SUCCESS, payload: userData })
  } catch (error) {
    yield put({ type: types.USER_DATA_SET_FAILED, payload: error })
  }
}

export default function * userDataSaga () {
  yield takeLatest(types.USER_DATA_GET, userDataGet)
  yield takeLatest(types.USER_DATA_SET, userDataSet)
}
