import { assessmentTypes } from '../../utils/enums/assessmentTypes'

export const APPLICATION = (id) => id ? `/v3/applications/${id}` : '/v3/applications'
export const APPLICATION_CHECK_REQUIREMENTS = (id) => `/v3/applications/${id}/check-requirements`
export const ASSESSMENT = (id) => `/v3/assessments/${id}`
export const ASSESSMENT_RESPONSE = (id, applicationId) => `/v3/assessments/${id}/response?applicationId=${applicationId}`
export const AUTHORIZATION = '/v3/auth/me'
export const CHANGE_PASSWORD = '/v3/auth/change-password'
export const MY_COUPONS = '/v3/coupon/my/coupons'
export const COUPON_VALIDATE = (coupon) => `/v3/coupon/use/${coupon}`
export const COURSE = (slug) => `/v3/courses/${slug}`
export const COURSE_SUGGESTION = (id) => `/v3/courses?jobRoleId=${id}`
export const FORGOT_PASSWORD = '/v3/auth/forgot-password'
export const LOGIN = '/v3/auth/login'
export const FORMS = (id) => id ? `/v3/forms/${id}` : '/v3/forms'
export const FORMS_RESPONSE = (id, applicationId) => `/v3/forms/${id}/response?applicationId=${applicationId}`
export const JOB_LIST_AVAILABLE = '/v3/jobroles/available'
export const JOB_LIST = '/v3/jobroles'
export const JOB_DETAIL = (slug) => `/v3/jobroles/${slug}`
export const JOBS = '/v3/jobs'
export const REGISTER = '/v3/auth/register'
export const RESET_PASSWORD = '/v3/auth/reset-password'
export const USER = '/v3/users/userdata'
export const COMPANIES = '/v3/companies'
export const USER_COMPANY = (id) => `/v3/companies/${id}/users`
export const GET_ASSESSMENTS = (id) => `/v3/assessments?applicationId=${id}&category=${assessmentTypes.TESTE_SITUACIONAL}`
export const GET_GATEKEEPERS = (id) => `/v3/assessments?applicationId=${id}&category=${assessmentTypes.PORTEIRO}`
