import * as types from './types'

export const couponLoad = () => ({
  type: types.COUPON_GET
})

export const couponError = error => ({
  type: types.COUPON_GET_FAILED,
  error
})

export const couponCloseError = () => ({
  type: types.COUPON_CLOSE_ERROR
})

export const couponValidate = (data) => ({
  type: types.COUPON_VALIDATE_GET,
  data
})

export const couponResetSuccess = () => ({
  type: types.COUPON_RESET_SUCCESS
})
