import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { passwordReset } from './requests'

export function * passwordResetSet (action) {
  try {
    yield put({ type: types.PASSWORD_RESET_LOAD })
    const token = yield call(passwordReset, action.data)
    yield put({ type: types.PASSWORD_RESET_SUCCESS, payload: { data: { token } } })
  } catch (e) {
    yield put({ type: types.PASSWORD_RESET_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * passwordResetSaga () {
  yield takeLatest(types.PASSWORD_RESET_SET, passwordResetSet)
}
