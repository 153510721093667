class ApplicationSteps {
  constructor () {
    this.steps = [
      {
        title: 'Preenchimento da candidatura',
        description: 'Preencha todos os campos para enviar a sua candidatura para a empresa responsável pela oportunidade.',
        status: 'in-progress'
      },
      {
        title: 'Procurando empresas',
        description: 'Estamos procurando as empresas que combinam com o seu perfil para enviar a sua candidatura.',
        status: 'ready'
      }
      // {
      //   title: 'Empresa encontrada',
      //   description: 'A sua candidatura foi enviada para uma de nossas empresas parceiras. Próximos passos enviados por e-mail.'
      // },
      // {
      //   title: 'Contato da empresa',
      //   description: 'Fique atento ao seu e-mail e celular, pois a empresa que te escolheu vai entrar em contato com você.'
      // },
      // {
      //   title: 'Gerando renda',
      //   description: 'Estamos muito felizes pela sua conquista. Desejamos muito sucesso e conte conosco sempre que desejar.'
      // }
    ]
  }

  getStep = (index) => {
    /* THIS IS AN WORK AROUND FOR A POSSIBLE BUG */
    if (index === 0) {
      return this.steps[0]
    }

    return this.steps[index - 1]
  }

  getAllSteps = () => {
    return this.steps
  }
}

export default ApplicationSteps
