import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const getUserData = async () => {
  let userData;
  try {
    const response = await VojoAxios.get(apiEndpoints.USER)
    userData = response?.data.data
  } catch (errResponse) {
    if(errResponse.response.status == 404){
      userData = {}
    }else{
      const error = errResponse.response.data.error
      throw error
    }
  }
  const userAccount = await getAuthUser()
  return { userData, userAccount }
}

const getAuthUser = async () => {
  try {
    const userAccountData = await VojoAxios.get(apiEndpoints.AUTHORIZATION)
    return userAccountData.data.data 
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}


export const setUserData = async (data) => {
  try {
    const userData = await VojoAxios.put(apiEndpoints.USER, data)
    return { userData: userData.data.data }
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
