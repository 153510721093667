import * as types from './types'

export const applicationSubmitLoad = () => ({
  type: types.APPLICATION_SUBMIT_LOAD
})

export const applicationSubmitSet = data => ({
  type: types.APPLICATION_SUBMIT_SET,
  data
})

export const applicationSubmitError = error => ({
  type: types.APPLICATION_SUBMIT_FAILED,
  error
})

export const applicationSubmitSuccess = () => ({
  type: types.APPLICATION_SUBMIT_SUCCESS
})

export const applicationUpdateLoad = () => ({
  type: types.APPLICATION_UPDATE_LOAD
})

export const applicationUpdateSet = data => ({
  type: types.APPLICATION_UPDATE_SET,
  data
})

export const applicationUpdateError = error => ({
  type: types.APPLICATION_UPDATE_FAILED,
  error
})

export const applicationUpdateSuccess = () => ({
  type: types.APPLICATION_UPDATE_SUCCESS
})

export const applicationUpdateClear = () => ({
  type: types.APPLICATION_UPDATE_CLEAR
})

export const applicationCheckLoad = () => ({
  type: types.APPLICATION_CHECK_LOAD
})

export const applicationCheckSet = data => ({
  type: types.APPLICATION_CHECK_SET,
  data
})

export const applicationCheckError = error => ({
  type: types.APPLICATION_CHECK_FAILED,
  error
})

export const applicationCheckSuccess = () => ({
  type: types.APPLICATION_CHECK_SUCCESS
})

export const applicationCheckClear = () => ({
  type: types.APPLICATION_CHECK_CLEAR
})

export const applicationClear = () => ({
  type: types.APPLICATION_CLEAR
})

export const applicationDataClear = () => ({
  type: types.APPLICATION_DATA_CLEAR
})
