import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const getJobDetail = async (data) => {
  try {
    const response = await VojoAxios.get(apiEndpoints.JOB_DETAIL(data.slug))
    return response.data.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
