import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  FooterEduk,
  TypographyEduk,
  StickyToast,
  themes,
  SpinnerEduk
} from '@mindlab-org/component-library'

import './ApplicationModules.sass'
import CustomHeader from '../../components/CustomHeader'
import ModulesArranger from './ModulesArranger'
import ApplicationSteps from './ApplicationSteps'
import { actions } from '../../redux/actions/actions'
import Intercom from '../../services/Intercom'
import VojoApplicationContent from './VojoApplicationContent'
import * as routes from '../../routes/routes'
import { Redirect } from 'react-router-dom'

class ApplicationModules extends Component {
  state = {
    allSteps: [],
    currentStep: {
      title: null,
      description: null,
      status: null
    },
    incompletedModules: [],
    completedModules: [],
    allModules: [],
    showModuleStepsModal: false,
    showModal: false,
    retry: true
  }

  componentDidMount () {
    const applicationId = this.props.match.params.id
    this.props.gatekeeper.approved && this.props.gatekeeperGet({ applicationId })
    this.props.applicationUpdateSet({ applicationId })
    this.props.formClear()
    this.props.formSubmitClear()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.gatekeeper.success && this.props.gatekeeper.data.length && this.props.gatekeeper.approved) {
      this.redirectGatekeeper(this.props.gatekeeper.data)
    }

    if (
      prevProps.application.application !== this.props.application.application &&
      this.props.application.application.success
    ) {
      const jobRoleId = this.props.application.application.data.application.jobRole.id
      this.setSteps()
      this.setModules()
      this.props.courseSuggestionSet({ jobRoleId })
    }

    if (prevState.currentStep.status !== this.state.currentStep.status) {
      this.setIntercomStatus()
      this.setAssessmentIntercomStatus()
      this.setFormIntercomStatus()
    }

    if (this.props.application.application.error && this.state.retry) {
      this.provisionalMeasureHandleError()
    }
  }

  // Método chamado para remover components do DOM
  componentWillUnmount () {
    this.props.applicationListClear()
    this.props.companyClear()
    this.props.applicationClear()
    this.props.gatekeeperClearResult()
  }

  /**
   *  @description **medida provisória** para o erro referente a VersionError
  */
  provisionalMeasureHandleError = () => {
    this.setState({ retry: false })
    const applicationId = this.props.match.params.id
    this.props.applicationDataClear()
    this.props.applicationUpdateSet({ applicationId })
  }

  redirectGatekeeper = (gatekeepers) => {
    const applicationId = this.props.match.params.id
    const getPendingAssessments = (gatekeeper) =>
      !gatekeeper.completed && !this.props.gatekeeper.assessmentsCompleted.includes(gatekeeper.id)
    gatekeepers.filter(getPendingAssessments).forEach(item => {
      const assessmentUrl = `${routes.ASSESSMENT(item.id)}?application_id=${applicationId}`
      this.props.history.push(assessmentUrl)
    })
  }

  /**
   *  @description altera showModuleStepsModal para **true**
  */
  handleShowModuleStepsModal = () => {
    this.setState({ showModuleStepsModal: true })
  }

  /**
   *  @description altera showModuleStepsModal para **false**
  */
  handleHideModuleStepsModal = () => {
    this.setState({ showModuleStepsModal: false })
  }

  setSteps = () => {
    const applicationSteps = new ApplicationSteps()
    const currentStep = this.props.application.application.data.application.currentStep
    this.setState({
      currentStep: applicationSteps.getStep(currentStep),
      allSteps: applicationSteps.getAllSteps()
    })
  }

  setModules = () => {
    const modules = this.props.application.application.data.application.modules
    const modulesFiltered = modules.filter(mod => mod.category !== 'PT')
    const arrangedModules = new ModulesArranger(modulesFiltered)
    arrangedModules.arrangeModules()
    this.setState({
      incompletedModules: arrangedModules.incompletedModules,
      completedModules: arrangedModules.completedModules,
      allModules: arrangedModules.allModules,
      arrangedModules: arrangedModules
    })
  }

  setIntercomStatus = () => {
    const intercom = new Intercom()
    intercom.jobStatusEvent(this.state.currentStep.status)
  }

  setAssessmentIntercomStatus = () => {
    const intercom = new Intercom()
    let assessmentData = this.state.incompletedModules.find(mod => mod.module.type === 'ASSESSMENT')

    if (assessmentData) {
      if (assessmentData.hasBlockedMessages) {
        intercom.assessmentStatusEvent('failed')
      } else {
        intercom.assessmentStatusEvent('incomplete')
      }
    } else {
      assessmentData = this.state.completedModules.find(mod => mod.module.type === 'ASSESSMENT')
      intercom.assessmentStatusEvent('completed')
    }
  }

  setFormIntercomStatus = () => {
    const intercom = new Intercom()
    let assessmentData = this.state.incompletedModules.find(mod => mod.module.type === 'FORM')

    if (assessmentData) {
      if (assessmentData.hasBlockedMessages) {
        intercom.formStatusEvent('failed')
      } else {
        intercom.formStatusEvent('incomplete')
      }
    } else {
      assessmentData = this.state.completedModules.find(mod => mod.module.type === 'FORM')
      intercom.formStatusEvent('completed')
    }
  }

  /**
   *  @description verifica a resposta do checkRequirements
   *  @returns **true** caso o usuário não passou na verificação, **false** do contrário
  */
  applicationFailsRequirements = () => {
    return this.props.application.checkRequirements.data.passed === false
  }

  /**
   *  @description retorna um componente de acordo com a página de origem
   *  @returns o componente que deve ser renderizado
  */
  renderContent = () => {
    const jobRoleSlug = this.props.application.application.data.application.jobRole.slug
    const content = {
      'entregador-james': <Redirect to={'/company/james-delivery'}/>,
      default: <VojoApplicationContent
        history={this.props.history}
        modules={this.state.arrangedModules}
        applicationId={this.props.match.params.id}
        jobRoleSlug={jobRoleSlug} />
    }
    return (content[jobRoleSlug] || content.default)
  }

  render () {
    return (
      <Container maxWidth="full">
        <Container maxWidth="full" marginBottom="30px">
          <CustomHeader history={this.props.history} />
          {
            (this.props.application.application.error ||
              this.props.application.applicationCreated.error ||
              this.applicationFailsRequirements()) && (
              <div style={{
                display: 'block',
                width: '100%',
                zIndex: '100'
              }}>
                <StickyToast show>
                  <TypographyEduk
                    color={themes.eduk.colors.redColor}
                    tag="span"
                    type="default">
                    {this.props.application.application.error && this.props.application.application.error.message}
                    {this.props.application.applicationCreated.error && this.props.application.applicationCreated.error.message}
                    {this.applicationFailsRequirements() && this.props.application.checkRequirements.data.reason}
                  </TypographyEduk>
                </StickyToast>
              </div>
            )
          }
        </Container>
        {
          this.props.application.application.isLoading
            ? (<SpinnerEduk spinnerArea="90vh" />)
            : this.props.application.application.success && (
              <Container maxWidth="desktop">
                <div className="ApplicationModules">
                  {this.state.arrangedModules && this.renderContent()}
                </div>
              </Container>
            )
        }
        <Container maxWidth="full">
          <FooterEduk />
        </Container>
      </Container >
    )
  }
}

const mapStateToProps = state => ({
  application: state.application,
  gatekeeper: state.gatekeeper
})

const mapDispatchToProps = dispatch => ({
  applicationUpdateSet: data => dispatch(actions.applicationUpdateSet(data)),
  applicationListClear: () => dispatch(actions.applicationListClear()),
  applicationClear: () => dispatch(actions.applicationClear()),
  applicationDataClear: () => dispatch(actions.applicationDataClear()),
  companyClear: () => dispatch(actions.companyClear()),
  courseSuggestionSet: data => dispatch(actions.courseSuggestionSet(data)),
  formClear: () => dispatch(actions.formClear()),
  formSubmitClear: () => dispatch(actions.formSubmitClear()),
  gatekeeperGet: data => dispatch(actions.gatekeeperGet(data)),
  gatekeeperClearResult: () => dispatch(actions.gatekeeperClearResult())
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationModules)
