import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'
import VojoAxios from '../../../settings/axios/VojoAxios'

export const addUser = async (payload) => {
  try {
    const params = { userId: payload.userId }
    const userCompany = await VojoAxios.post(apiEndpoints.USER_COMPANY(payload.companyId), { ...params })
    return userCompany.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}

export const companyGet = async (company) => {
  const companySlug = company || sessionStorage.getItem('companySlug')
  try {
    const company = await VojoAxios.get(apiEndpoints.COMPANIES + `/${companySlug}`)
    return company.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
