import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import QueryString from 'qs'
import {
  Container,
  FooterEduk,
  TypographyEduk,
  SpinnerEduk,
  StickyToast,
  themes,
  ButtonEduk,
  Snackbar,
  ModalActions,
  IconManager
} from '@mindlab-org/component-library'

import './Forms.sass'

import * as routes from '../../routes/routes'
import CustomHeader from '../../components/CustomHeader'
import { FieldBuilder } from '../../services/Forms'
import checkEmptyFields from '../../utils/forms/checkEmptyFields'
import { checkValidationFields } from '../../utils/forms/checkValidationFields'
import { actions } from '../../redux/actions/actions'
import { useForm, Controller } from 'react-hook-form'
import { Redirect } from 'react-router-dom'

const Forms = ({
  location,
  formData,
  application,
  applicationCheck,
  applicationCheckSet,
  applicationUpdateSet,
  formSubmitClear,
  match,
  formSet,
  formSubmitSet,
  history
}) => {
  const applicationId = QueryString.parse(location.search)['?application_id']
  const [formStatus, setFormStatus] = useState({
    showError: false,
    isCompleted: false,
    message: 'O formulário deve estar completamente preenchido.',
    errorFields: []
  })
  const { handleSubmit, setValue, control, getValues } = useForm({})
  const [showModal, setShowModal] = useState(false)
  const [fieldsId, setFieldsId] = useState(false)
  const [loading, setLoading] = useState(true)
  const jobRoleSlug = QueryString.parse(location.search).jobRoleSlug
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [requiredFields, setRequiredFields] = useState([])

  useEffect(() => {
    getFormData()
  }, [])

  /**
   * @description se a requisição para obter dados do formulário foi realizada com sucesso,
   * criar os campos do formulário
  */
  useEffect(() => {
    if (formData.formFetch.success) {
      createFieldState()
      setRequiredFields(
        formData.formFetch.data.fields.filter(field => field.required)
          .map(field => field.field.name)
      )
    }
  }, [formData.formFetch.data.fields])

  useEffect(() => {
    //  se o envio do formulário foi realizado com sucesso, verificar requisitos.
    if (formData.formSubmission.success) {
      applicationUpdateSet({ applicationId })
    }

    if (hasToCheckRequirements()) {
      applicationCheckSet(applicationId)
    }
  }, [
    formData.formSubmission.success,
    applicationCheck.success,
    application.application.success
  ])

  /**
   * @description se a verificação de requisitos falhou, e possui o
   * código correspondende a CEP inválido (200),
   */
  useEffect(() => {
    if (formData.formSubmission.success &&
      applicationCheck.error?.code === 200) {
      showErrorOnFields({ zipcode: { value: null } })
      formSubmitClear()
      setLoadingRequest(false)
    }
  }, [applicationCheck.error])

  const redirectToApplication = () => {
    if (formData.formFetch.data?.type !== 'GATEKEEPER' || applicationCheck.success) {
      return <Redirect to={routes.APPLICATION(applicationId)} />
    }
  }

  const hasToCheckRequirements = () => {
    return formData.formSubmission.success &&
      application.application.success &&
      formData.formFetch.data?.type === 'GATEKEEPER'
  }

  /**
   * @description Faz requisição para obter dados do formulário
   */
  const getFormData = () => {
    const requestData = { formId: match.params.id }
    formSet(requestData)
  }

  /**
   * @description recebe os dados do campo de formulário e retorna a resposta no formato correto
   * @param {*} field - dados do campo do formulário
   * @returns {*} valor de resposta preexistente
   */
  const getResponseFromField = (field) => {
    if (field.field.component === 'input' || field.field.component === 'select') {
      const inputResponse = field.response.value
      return field.response.value
        ? inputResponse
        : null
    } else if (field.field.component === 'multiSelect') {
      return field.response?.option.map(opt => ({ label: opt, value: opt }))
    } else if (field.response.option instanceof Array) {
      return field.response.option.map(opt => String(opt))
    } else {
      return (field.response.option !== -1 || field.response.option !== null)
        ? field.response.option
        : null
    }
  }

  /**
   * @description atribui valores para os campos usando a função setValue, da biblioteca react-hook-form.
   * Salva uma lista de IDs dos campos e verifica se todas as respostas preexistente estão completas.
   * Ao terminar de processar todos os campos, é atribuido o valor false para a variável loading.
   */
  const createFieldState = async () => {
    const fields = {}
    const verifyForm = {}
    formData.formFetch.data.fields.forEach(field => {
      fields[field.field.name] = field.field.id
      const isMultiSelect = field.field.component === 'multiSelect'
      const createField = isMultiSelect
        ? { value: getResponseFromField(field) }
        : {
          fieldId: field.field.id,
          errorMessage: '',
          isValid: true,
          value: field.response ? getResponseFromField(field) : null
        }

      setValue(field.field.name, createField)
      verifyForm[field.field.name] = { createField, required: field.required }
    })
    setFieldsId(fields)
    isFormCompleted(verifyForm)
    setLoading(false)
  }

  /**
   * @param {*} field - campo do formulário
   * @returns valor da resposta
   */
  const getFieldValue = (field) => {
    const { value, code } = field
    if (Array.isArray(value)) {
      return value.map(val => String(val.value !== undefined ? val.value : val))
    }

    if (value !== undefined) {
      return (value === 'null') ? null : value
    }

    if (code !== undefined) return code

    if (Array.isArray(field)) {
      // Se o field contem objetos com value, ao inves do value isolado
      if (field[0].value !== undefined) return field.map((field) => field.value)

      return field
    }

    return null
  }

  /**
   * @description remove um valor da lista de campos inválidos
   * @param {string} value
   */
  const removeFieldFromErrorList = (value) => {
    const newValue = formStatus.errorFields
      .filter(name => name !== value)

    setFormStatus({
      ...formStatus,
      errorFields: newValue,
      isCompleted: newValue.length === 0
    })
  }

  /**
   * @description se o formulário estiver completo, iniciar o processo de envio de formulário,
   * caso contrário, acionar o tratamento de erros
   * @param {object} data - objeto contendo os campos do formulário
   */
  const onSubmit = async (data) => {
    isFormCompleted(data) ? handleFormSubmit() : showErrorOnFields(data)
  }

  /**
  * @returns **true** caso usuário não tenha informado o dígito da conta, **false** do contrário.
  */
  const userDidntInputBankAccountDigit = () => {
    const form = getValues()
    return form?.accountDigit &&
      !form?.accountDigit?.value &&
      form.accountDigit.value !== 0
  }

  /**
   * @description Retorna um array de objetos field contendo o fieldId e o value de cada campo.
   * @returns {Object[]} fieldsResponse
  */
  const buildFormData = () => {
    const fieldsResponse = []
    const form = getValues()

    // Caso a pessoa não inseriu o digito da conta
    if (userDidntInputBankAccountDigit()) {
      const bankAccountValue = form?.bankAccount?.value
      const correctedValueAccount = bankAccountValue.substr(0, bankAccountValue.length - 1)
      const correctedValueDigit = bankAccountValue[bankAccountValue.length - 1]

      setValue('accountDigit.value', correctedValueDigit)
      setValue('bankAccount.value', correctedValueAccount)
    }

    Object.entries(fieldsId).forEach(([fieldName, fieldId]) => {
      const fieldValue = getFieldValue(form[fieldName])
      const dataObject = {
        fieldId: fieldId,
        value: (fieldValue instanceof Array || fieldValue === null) ? (fieldValue) : String(fieldValue)
      }
      fieldsResponse.push(dataObject)
    })

    return fieldsResponse
  }

  /**
   * @description Move a tela até o campo informado
   * @param {string} name - id do campo
   * @returns {void}
   */
  const scrollToErrorField = (name) => {
    const field = document.getElementById(name)
    field && field.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  /**
   * @description atualiza no estado os campos que não estão preenchidos e move a tela até o primeiro campo
  */
  const showErrorOnFields = async (data) => {
    const errorFields = checkEmptyFields(data, requiredFields)
    setFormStatus({
      ...formStatus,
      showError: true,
      errorFields: errorFields,
      isCompleted: false
    })
    scrollToErrorField(errorFields[0])
  }

  /**
   * @description verifica se o formulário foi completamente preenchido.
   * @param {object} form - objeto com os campos do formulário
   * @returns **true** se completo, **false** do contrário.
  */
  const isFormCompleted = (form) => {
    const errorFields = checkEmptyFields(form, requiredFields)
    const isCompleted = errorFields.length === 0
    setFormStatus({
      ...formStatus,
      isCompleted: isCompleted,
      errorFields: errorFields
    })

    return isCompleted
  }

  /**
  * @description faz requisição para salvar os dados.
  */
  const handleFormSubmit = () => {
    setFormStatus({
      ...formStatus,
      showError: false
    })

    const errorFields = checkValidationFields(getValues())

    if (errorFields.length) return scrollToErrorField(errorFields[0])

    const formData = {
      responses: buildFormData(),
      formId: match.params.id,
      isUpdate: false,
      applicationId: applicationId
    }

    setLoadingRequest(true)
    formSubmitSet(formData)
  }

  const showStickyToast = () => {
    return formData.formFetch.error ||
      formData.formSubmission.error ||
      applicationCheck.error
  }

  /**
   * @description Retorna a classe do SASS referente ao tipo do campo informado.
   * @param {string} fieldName - nome do campo.
   * @returns {String} A classe do SASS.
  */
  const getVariableClassName = (fieldName) => {
    const styles = { default: 'Forms__Form__Field' }
    styles.branchDigit = styles.accountDigit = 'Forms__Form__PartialSmallerField'
    styles.bankAccount = styles.bankBranch = 'Forms__Form__PartialLargerField'
    return (styles[fieldName] || styles.default)
  }

  const renderJamesStyle = (name) => {
    const styles = {
      cpf:
        <div className='Category'>
          <div className='Category__Title'>
            <TypographyEduk
              fontSize={'18px'}
              type={'title'}
              fontWeight={'700'}
              letterSpacing={'0.25px'}
              lineHeight={'20px'}>
              Dados bancários
            </TypographyEduk>
            <div onClick={() => setShowModal(true)} className='Category__Icon'>
              <IconManager fill={themes.eduk.colors.primaryColor} icon={'QuestionCircle'} />
            </div>
          </div>
          <TypographyEduk
            fontWeight={'400'}
            lineHeight={'18px'}
            color={themes.eduk.colors.lightGreyColor}>
            Estamos quase lá! Estes dados serão utilizados apenas para efetuarmos os seus pagamentos.
          </TypographyEduk>
        </div>,
      cnh:
        <div className='Category'>
          <TypographyEduk
            fontSize={'18px'}
            type={'title'}
            lineHeight={'20px'}
            letterSpacing={'0.25px'}
            fontWeight={'700'}>
            Dados do Motorista
          </TypographyEduk>
        </div>,
      modal:
        <ModalActions
          textAlign={'center'}
          title={'Por que estamos pedindo isso?'}
          actions={<ButtonEduk retangular={true} onButtonClick={() => setShowModal(false)}>Ok, entendi!</ButtonEduk>}
          modalWidth={'280px'}>
          <div className="ModalBank">
            <div className="ModalBank__Wrapper">
              <TypographyEduk
                lineHeight={'18px'}
                fontWeight={'300'}
                color={themes.eduk.colors.lightGreyColor}>
                Os dados bancários são importantes para termos uma forma de efetuar os seus pagamentos e o CPF será utilizado como um meio de autenticar o seu acesso à plataforma.
              </TypographyEduk>
            </div>
            <div className="ModalBank__Wrapper">
              <TypographyEduk
                lineHeight={'18px'}
                fontWeight={'300'}
                color={themes.eduk.colors.lightGreyColor}>
                Assim é possível garantir que o acesso é exclusivamente seu!
              </TypographyEduk>
            </div>
          </div>
        </ModalActions>,
      snackbar:
        <Container maxWidth="mobile">
          <div className="FormCompany">
            <Snackbar
              backgroundColor={themes.eduk.colors.purpleColor}
              borderRadius={'10px'}
              justifyText={'space-between'}
              height={'52px'}
              title={'Entregador'}
              titleColor={themes.eduk.colors.whiteColor}
              titleSize={'18px'}
              image={<img alt={'logo james delivery'} width={'44px'}
                src={'https://s3.amazonaws.com/static.vojo.com.br/prd/images/james_logo_nome.svg'} />} />
          </div>
        </Container>,
      default: <div></div>
    }
    return (styles[name] || styles.default)
  }

  const handleStyle = (fieldName) => {
    const style = {
      'entregador-james': renderJamesStyle(fieldName)
    }
    return style[jobRoleSlug]
  }

  const renderComponents = () => {
    return formData.formFetch.data.fields
      ? formData.formFetch.data.fields.map(field => {
        return (
          <>
            {handleStyle(field.field.name)}
            <div className={getVariableClassName(field.field.name)}
              key={field.field.name}>
              <Controller
                name={field.field.name}
                control={control}
                defaultValue={field.response}
                render={({ field: { onChange, value } }) => (
                  <FieldBuilder
                    field={{ ...field, ...field.field }}
                    errorMessage={''}
                    hasError={formStatus.showError && formStatus.errorFields.includes(field.field.name)}
                    onInputChange={(e) => {
                      onChange(e)
                      formStatus.errorFields.includes(field.field.name) &&
                        removeFieldFromErrorList(field.field.name)
                    }}
                    onToolTipClick={() => { /* Por enquanto nao faz nada */ }}
                    value={value}
                    valueSelect={value}
                    defaultValue={field.response}
                  />)}
              />
              {field.field.name === 'zipcode' &&
                <div className="Cep">
                  <div className="Cep__Clickable" onClick={() => window.open('https://buscacepinter.correios.com.br/app/endereco/index.php?t')}>
                    <TypographyEduk color={themes.eduk.colors.primaryColor} fontSize={'14px'} fontWeight={'500'}>não sei o CEP</TypographyEduk>
                  </div>
                </div>}
              {field.field.title === 'Dígito*' &&
                <div className="Digit">
                  <TypographyEduk tag={'span'} color={themes.eduk.colors.lightGreyColor}>*se houver</TypographyEduk>
                </div>}
            </div>
          </>
        )
      })
      : null
  }

  const renderForms = () => {
    return (
      loading
    )
      ? (<SpinnerEduk spinnerArea="80vh" />)
      : (
        <div className="Forms__Form">
          <div>
            <div className="Forms__Form__Title">
              <TypographyEduk
                fontSize="18px"
                color={themes.eduk.colors.blackColor2}
                fontWeight={'700'}
                letterSpacing={'0.25px'}
                lineHeight={'20px'}
                fontFamily={"'Montserrat', sans-serif"}>
                {formData.formFetch.data.title}
              </TypographyEduk>
            </div>
            <div className="Forms__Form__Description">
              <TypographyEduk
                fontSize="14px"
                fontWeight={'400'}
                lineHeight={'18px'}
                color={themes.eduk.colors.lightGreyColor}>
                {formData.formFetch.data.description}
              </TypographyEduk>
            </div>
            <form className="Forms__Form__Fields">
              {renderComponents()}
            </form>
          </div>
          <div className="Forms__Form__Submit">
            {loadingRequest
              ? (<SpinnerEduk />)
              : (<ButtonEduk
                retangular={true}
                id="send-form"
                name="send"
                backgroundColor={formStatus.isCompleted ? themes.eduk.colors.primaryColor : themes.eduk.colors.smokeColor}
                onButtonClick={handleSubmit(onSubmit)}
              ><TypographyEduk
                  fontSize="14px"
                  fontWeight={'500'}
                  lineHeight={'18px'}
                  letterSpacing={'1px'}
                  color={formStatus.isCompleted
                    ? themes.eduk.colors.whiteColor
                    : themes.eduk.colors.textMutedColor}
                >Enviar
                </TypographyEduk>
              </ButtonEduk>)}
          </div>
        </div>
      )
  }

  return (
    <Container maxWidth="full">
      {formData.formSubmission.success && redirectToApplication()}
      <Container maxWidth="full">
        <CustomHeader history={history} />
        {
          (
            showStickyToast()
          ) && (
            <div style={{
              display: 'block',
              width: '100%',
              zIndex: '100'
            }}>
              <StickyToast show>
                <TypographyEduk
                  color={themes.eduk.colors.pinkColor}
                  tag="span"
                  type="default">
                  {
                    formData.formFetch.error &&
                    formData.formFetch.error.message
                  }
                  {
                    formData.formSubmission.error &&
                    formData.formSubmission.error.message
                  }
                  {
                    applicationCheck.error &&
                    applicationCheck.error.message
                  }
                </TypographyEduk>
              </StickyToast>
            </div>
          )
        }
        {showModal && handleStyle('modal')}
      </Container>
      <Container maxWidth="mobile">
        {handleStyle('snackbar')}
        <div className="Forms">
          {renderForms()}
        </div>
      </Container>
      <Container maxWidth="full">
        <FooterEduk />
      </Container>
    </Container>
  )
}

const mapStateToProps = state => ({
  formData: state.form,
  applicationCheck: state.application.checkRequirements,
  application: state.application
})

const mapDispatchToProps = dispatch => ({
  formSet: data => dispatch(actions.formSet(data)),
  formSubmitSet: data => dispatch(actions.formSubmitSet(data)),
  applicationCheckSet: data => dispatch(actions.applicationCheckSet(data)),
  formSubmitClear: () => dispatch(actions.formSubmitClear()),
  applicationUpdateSet: data => dispatch(actions.applicationUpdateSet(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Forms)
