import * as types from '../../actions/userData/types'

const defaultState = {
  data: {},
  isLoading: false,
  success: false,
  error: false
}

const userDataGetLoad = state => ({
  ...defaultState,
  ...state,
  isLoading: true
})

const userDataGet = (state) => ({
  ...defaultState,
  ...state,
  isLoading: false
})

const userDataGetSuccess = (state, payload) => ({
  ...defaultState,
  ...state,
  data: payload.userData,
  account: payload.userAccount,
  isLoading: false
})

const userDataGetError = (state, payload) => ({
  ...defaultState,
  ...state,
  error: payload.error,
  isLoading: false
})

const userDataSetLoad = state => ({
  ...defaultState,
  ...state,
  isLoading: true
})

const userDataSetSuccess = (state, payload) => ({
  ...defaultState,
  ...state,
  data: payload.userData,
  success: true,
  isLoading: false
})

const userDataSetError = (state, payload) => ({
  ...defaultState,
  ...state,
  error: payload,
  isLoading: false,
  success: false
})

const userDataReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.USER_DATA_GET:
      return userDataGet(state)
    case types.USER_DATA_GET_LOAD:
      return userDataGetLoad(state)
    case types.USER_DATA_GET_SUCCESS:
      return userDataGetSuccess(state, action.payload)
    case types.USER_DATA_GET_FAILED:
      return userDataGetError(state, action.payload)
    case types.USER_DATA_SET_LOAD:
      return userDataSetLoad(state)
    case types.USER_DATA_SET_SUCCESS:
      return userDataSetSuccess(state, action.payload)
    case types.USER_DATA_SET_FAILED:
      return userDataSetError(state, action.payload)
    default:
      return state
  }
}

export default userDataReducer
