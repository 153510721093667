import * as types from '../../actions/gatekeeper/types'

const defaultState = {
  data: [],
  approved: true,
  assessmentsCompleted: [],
  isLoading: false,
  error: null,
  success: false
}

const loadGatekeeper = (state) => ({
  ...state,
  isLoading: true
})

const successGatekeeper = (state, payload) => ({
  ...state,
  data: payload.data,
  error: null,
  isLoading: false,
  success: true
})

const errorGatekeeper = (state, payload) => ({
  ...state,
  data: payload.data,
  error: true,
  isLoading: false,
  success: false
})

const gatekeeperResult = (state, data) => ({
  ...state,
  assessmentsCompleted: [...state.assessmentsCompleted, data.assessmentId],
  approved: data.approved
})

const gatekeeperClearResult = (state) => ({
  ...state,
  approved: true
})

const gatekeeperReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.GATEKEEPER_LOAD:
      return loadGatekeeper(state)

    case types.GATEKEEPER_SUCCESS:
      return successGatekeeper(state, action.payload)

    case types.GATEKEEPER_RESULT:
      return gatekeeperResult(state, action.data)

    case types.GATEKEEPER_ERROR:
      return errorGatekeeper(state, action.payload)

    case types.GATEKEEPER_CLEAR:
      return gatekeeperClearResult(state)

    default:
      return state
  }
}

export default gatekeeperReducer
