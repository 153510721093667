import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { getCourse, getCourseSuggestion } from './requests'

export function * courseSet (action) {
  try {
    yield put({ type: types.COURSE_GET_LOAD })
    const courseData = yield call(getCourse, action.data)
    yield put({ type: types.COURSE_GET_SUCCESS, payload: { data: courseData } })
  } catch (e) {
    yield put({ type: types.COURSE_GET_FAILED, payload: { error: e } })
  }
}

export function * courseSuggestionSet (action) {
  try {
    yield put({ type: types.COURSE_GET_LOAD })
    const courseData = yield call(getCourseSuggestion, action.data)
    yield put({ type: types.COURSE_GET_SUCCESS, payload: { data: courseData } })
  } catch (e) {
    yield put({ type: types.COURSE_GET_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * formSaga () {
  yield takeLatest(types.COURSE_GET, courseSet)
  yield takeLatest(types.COURSE_SUGGESTION_GET, courseSuggestionSet)
}
