import React from 'react'

import handlersMap from './handlersMap'

export default function DynamicPageRenderer ({ components }) {
  return (
    <>
      {components.map(({ props, component }, index) => {
        const Handler = handlersMap[component.type]
        if (!Handler) throw new RangeError(`Componente do tipo ${component.type} não está registrado!`)
        return <Handler key={component.type + index} {...props} />
      })}
    </>
  )
}
