import * as types from '../../actions/login/types'

const defaultState = {
  isLoading: false,
  error: null,
  success: false
}

const loadLogin = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successLogin = (state) => ({
  ...state,
  error: null,
  isLoading: false,
  success: true
})

const failLogin = (state, payload) => ({
  ...state,
  error: payload.error,
  isLoading: false,
  success: false
})

const registerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOGIN_LOAD:
      return loadLogin(state)

    case types.LOGIN_SUCCESS:
      return successLogin(state)

    case types.LOGIN_FAILED:
      return failLogin(state, action.payload)

    default:
      return state
  }
}

export default registerReducer
