import * as types from '../../actions/job/types'

const defaultState = {
  data: [],
  isLoading: false,
  error: null,
  success: false
}

const loadJob = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successJob = (state, payload) => ({
  ...state,
  data: payload.data,
  error: null,
  isLoading: false,
  success: true
})

const failJob = (state, payload) => ({
  ...state,
  data: [],
  error: payload.error,
  isLoading: false,
  success: false
})

const clearJob = () => ({
  ...defaultState
})

const jobReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.JOB_LOAD:
      return loadJob(state)

    case types.JOB_SUCCESS:
      return successJob(state, action.payload)

    case types.JOB_FAILED:
      return failJob(state, action.payload)

    case types.JOB_CLEAR:
      return clearJob()

    default:
      return state
  }
}

export default jobReducer
