import { call, put, takeLatest } from 'redux-saga/effects'
import types from '../../actions/types'
import { companyGet, addUser } from './requests'

export function * relateUsers (action) {
  try {
    yield put({ type: types.ADD_USER_LOAD })
    const response = yield call(addUser, action.payload)
    yield put({ type: types.ADD_USER_SUCCESS, payload: { data: response.data } })
  } catch (e) {
    yield put({ type: types.ADD_USER_FAILED, payload: { error: e } })
  }
}

export function * getCompany (action) {
  try {
    yield put({ type: types.COMPANY_SET_LOAD })
    const company = yield call(companyGet, action.companySlug)
    yield put({ type: types.COMPANY_SET_SUCCESS, payload: company.data })
  } catch (e) {
    yield put({ type: types.COMPANY_SET_FAILED, payload: { error: e } })
  }
}

export default function * companySaga () {
  yield takeLatest(types.ADD_USER, relateUsers)
  yield takeLatest(types.COMPANY_GET, getCompany)
}
