export const ASSESSMENT_GET = 'ASSESSMENT_GET'
export const ASSESSMENT_GET_SUCCESS = 'ASSESSMENT_GET_SUCCESS'
export const ASSESSMENT_GET_FAILED = 'ASSESSMENT_GET_FAILED'
export const ASSESSMENT_GET_CLEAR = 'ASSESSMENT_GET_CLEAR'
export const ASSESSMENT_GET_LOAD = 'ASSESSMENT_GET_LOAD'

export const ASSESSMENT_SET = 'ASSESSMENT_SET'
export const ASSESSMENT_SET_LOAD = 'ASSESSMENT_SET_LOAD'
export const ASSESSMENT_SET_SUCCESS = 'ASSESSMENT_SET_SUCCESS'
export const ASSESSMENT_SET_FAILED = 'ASSESSMENT_SET_FAILED'
export const ASSESSMENT_SET_CLEAR = 'ASSESSMENT_SET_CLEAR'
