import React from 'react'
import DynamicPageRenderer from '../../components/DynamicPageRenderer'
import { getPages } from '../../settings/apiEndpoints/queries'
import FeedbackMessage from '../../components/FeedbackMessage'
import { SpinnerEduk, StickyToast, TypographyEduk, themes, ButtonEduk } from '@mindlab-org/component-library'
import useQuery from '../../hooks/useQuery'
import { routesEduk } from '../../routes/routesEduk'

const paragraphs = [
  'Seu processo na eduK | Gerando Renda foi aprovado, agora é com a empresa!',
  'Fique atento aos seus canais de comunicação cadastrados - telefone, whatsapp ou e-mail.',
  'Enquanto isso, conheça outras oportunidades de renda disponíveis em nosso site!'
]

const errorStickToast = (error) => (
  <StickyToast show>
    <TypographyEduk
      color={themes.eduk.colors.redColor}
      tag="span"
      type="default">
      {error}
    </TypographyEduk>
  </StickyToast>
)

const defaultPage = () => {
  return (
    <div className='FeedbackMessage'>
      <TypographyEduk
        tag={'h2'}
        fontSize={'18px'}
        fontWeight={'bold'}
        fontFamily={'Montserrat'}
        lineHeight={'20px'}
        color={themes.eduk.colors.primaryColor}
        letterSpacing={'0.25px'}>
      Você completou todas as etapas!
      </TypographyEduk>
      {paragraphs.map((item, index) => (
        <div key={index} className='FeedbackMessage__Text'>
          <TypographyEduk
            fontWeight={'400'}
            lineHeight={'18px'}
            color={themes.eduk.colors.greyColor}
            letterSpacing={'0.4px'}>
            {item}
          </TypographyEduk>
        </div>
      ))
      }
      <a href={routesEduk.jobList} target='_blank' rel="noreferrer">
        <ButtonEduk
          backgroundColor={themes.eduk.colors.primaryColor}
          borderColor={themes.eduk.colors.primaryColor}
          retangular
          width={'232px'}
          heigth={'36px'}
          padding={'10px 20px'}
        >
          <TypographyEduk
            tag={'span'}
            fontWeigth={'500'}
            letterSpacing={'1px'}
            color={themes.eduk.colors.whiteColor}
          >
          Ver oportunidades
          </TypographyEduk>
        </ButtonEduk>
      </a>
    </div>
  )
}

const handleError = (error) => {
  if (error.request.status === 404) return defaultPage()
  return errorStickToast(error.data.error.message)
}

export default function DynamicComponents ({ jobRoleId }) {
  const [isLoading, error, data] = useQuery(getPages(jobRoleId, 'page_application_ready'))

  if (isLoading) return <SpinnerEduk spinnerArea="50vh" />

  if (error) return handleError(error.response)

  return (
    <FeedbackMessage>
      <DynamicPageRenderer components={data?.components} />
    </FeedbackMessage>
  )
}
