import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const validateCoupon = async (data) => {
  try {
    const response = await VojoAxios.post(apiEndpoints.COUPON_VALIDATE(data))
    return response.data
  } catch (errResponse) {
    const error = errResponse.response.data.error.message || errResponse.response.data.error
    throw error
  }
}

export const getUserCoupons = async () => {
  try {
    const response = await VojoAxios.get(apiEndpoints.MY_COUPONS)
    return { coupons: response.data }
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
