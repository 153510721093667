class Intercom {
  constructor () {
    this.intercom = window.Intercom
  }

  isIntercomValid = () => {
    return Boolean(this.intercom)
  }

  connect = (authData, company) => {
    if (this.isIntercomValid()) {
      if (authData) {
        const companyId = authData.companyId ? authData.companyId : company?.id
        this.intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_KEY,
          name: `${authData.firstName} ${authData.lastName}`,
          email: authData.email,
          phone: authData.cellphone,
          user_id: authData.id,
          company: {
            company_id: companyId
          }
        })
      } else {
        this.intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_KEY
        })
      }
    }
  }

  assessmentStatusEvent = (status) => {
    if (this.isIntercomValid()) {
      this.intercom('trackEvent', `${status}-assessment`)
    }
  }

  formStatusEvent = (status) => {
    if (this.isIntercomValid()) {
      this.intercom('trackEvent', `${status}-form`)
    }
  }

  jobStatusEvent = (status) => {
    if (this.isIntercomValid()) {
      this.intercom('trackEvent', `${status}-job`)
    }
  }

  hideIntercomButton = () => {
    if (this.isIntercomValid()) {
      this.intercom('update', {
        hide_default_launcher: true
      })
    }
  }
}

export default Intercom
