import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const getCourse = async (data) => {
  try {
    const response = await VojoAxios.get(apiEndpoints.COURSE(data.slug))
    window.open(response.data.data.thinkificUrl)
    return response.data.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}

export const getCourseSuggestion = async (data) => {
  try {
    const response = await VojoAxios.get(apiEndpoints.COURSE_SUGGESTION(data.jobRoleId))
    return response.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
