import * as types from './types'

export const applicationListSet = data => ({
  type: types.APPLICATION_LIST_GET,
  data
})

export const applicationListLoad = () => ({
  type: types.APPLICATION_LIST_GET_LOAD
})

export const applicationListError = error => ({
  type: types.APPLICATION_LIST_GET_FAILED,
  error
})

export const applicationListSuccess = () => ({
  type: types.APPLICATION_LIST_GET_SUCCESS
})

export const applicationListClear = () => ({
  type: types.APPLICATION_LIST_GET_CLEAR
})
