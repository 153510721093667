export const COMPANY_GET = 'COMPANY_GET'
export const COMPANY_SET = 'COMPANY_SET'
export const COMPANY_SET_LOAD = 'COMPANY_SET_LOAD'
export const COMPANY_SET_SUCCESS = 'COMPANY_SET_SUCCESS'
export const COMPANY_SET_FAILED = 'COMPANY_SET_FAILED'
export const COMPANY_CLEAR = 'COMPANY_CLEAR'
export const ADD_USER = 'ADD_USER'
export const ADD_USER_LOAD = 'ADD_USER_LOAD'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAILED = 'ADD_USER_FAILED'
