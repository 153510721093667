import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { updateApplication, submitApplication, checkRequirements } from './requests'

export function * applicationUpdateSet (action) {
  try {
    yield put({ type: types.APPLICATION_UPDATE_LOAD })
    const applicationData = yield call(updateApplication, action.data)
    yield put({ type: types.APPLICATION_UPDATE_SUCCESS, payload: { data: applicationData } })
  } catch (e) {
    yield put({ type: types.APPLICATION_UPDATE_FAILED, payload: { error: e } })
  }
}

export function * applicationSubmitSet (action) {
  try {
    yield put({ type: types.APPLICATION_SUBMIT_LOAD })
    const applicationData = yield call(submitApplication, action.data)
    yield put({ type: types.APPLICATION_SUBMIT_SUCCESS, payload: { data: applicationData } })
  } catch (e) {
    yield put({ type: types.APPLICATION_SUBMIT_FAILED, payload: { error: e } })
  }
}
export function * applicationCheckSet (action) {
  try {
    yield put({ type: types.APPLICATION_CHECK_LOAD })
    const applicationData = yield call(checkRequirements, action.data)
    yield put({ type: types.APPLICATION_CHECK_SUCCESS, payload: { data: applicationData } })
  } catch (e) {
    yield put({ type: types.APPLICATION_CHECK_FAILED, payload: { error: e } })
  }
}
// This will watch for the actions dispatches
export default function * applicationSaga () {
  yield takeLatest(types.APPLICATION_UPDATE_SET, applicationUpdateSet)
  yield takeLatest(types.APPLICATION_SUBMIT_SET, applicationSubmitSet)
  yield takeLatest(types.APPLICATION_CHECK_SET, applicationCheckSet)
}
