import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { getForm, submitForm, updateForm } from './requests'

export function * formSet (action) {
  try {
    yield put({ type: types.FORM_LOAD })
    const formData = yield call(getForm, action.data)
    yield put({ type: types.FORM_SUCCESS, payload: { data: formData } })
  } catch (e) {
    yield put({ type: types.FORM_FAILED, payload: { error: e } })
  }
}

export function * formSubmitSet (action) {
  try {
    yield put({ type: types.FORM_SUBMIT_LOAD })
    const formData = action.data.isUpdate
      ? yield call(updateForm, action.data)
      : yield call(submitForm, action.data)
    yield put({ type: types.FORM_SUBMIT_SUCCESS, payload: { data: formData } })
  } catch (e) {
    yield put({ type: types.FORM_SUBMIT_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * formSaga () {
  yield takeLatest(types.FORM_SET, formSet)
  yield takeLatest(types.FORM_SUBMIT_SET, formSubmitSet)
}
