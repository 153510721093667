import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { passwordChange } from './requests'

export function * passwordChangeSet (action) {
  try {
    yield put({ type: types.PASSWORD_CHANGE_LOAD })
    yield call(passwordChange, action.data)
    yield put({ type: types.PASSWORD_CHANGE_SUCCESS })
  } catch (e) {
    yield put({ type: types.PASSWORD_CHANGE_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * passwordChangeSaga () {
  yield takeLatest(types.PASSWORD_CHANGE_SET, passwordChangeSet)
}
