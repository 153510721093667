import ButtonHandler from './handlers/ButtonHandler'
import BannerImageHandler from './handlers/BannerImageCardHandler'
import TypographyHandler from './handlers/TypographyHandler'

const handlersMap = {
  button: ButtonHandler,
  bannerImageCard: BannerImageHandler,
  typography: TypographyHandler
}

export default handlersMap
