import { Snackbar, ButtonEduk, Container, TypographyEduk, themes } from '@mindlab-org/component-library/dist/index.js'
import React, { Component } from 'react'
import CustomHeader from '../../components/CustomHeader/CustomHeader'
import * as routes from '../../routes/routes'
import './CompanyJob.sass'

class CompanyJobJames extends Component {
  render () {
    return (
      <Container maxWidth="full">
        <CustomHeader blockMenu={true} history={this.props.history} />
        <div className="Snackbar">
          <Snackbar
            backgroundColor={themes.eduk.colors.purpleColor}
            borderRadius={'10px'}
            justifyText={'space-between'}
            height={'52px'}
            title={'Entregador'}
            titleColor={themes.eduk.colors.whiteColor}
            titleSize={'18px'}
            image={<img alt={'logo james delivery'} width={'45px'}
              src={'https://s3.amazonaws.com/static.vojo.com.br/prd/images/james_logo_nome.svg'} />} />
        </div>
        <div className="CompanyJobJames__Message">
          <div className="CompanyJobJames__Message__Wrapper">
            <h3>A candidatura da James mudou!</h3>
          </div>
          <div className="CompanyJobJames__Message__Wrapper">
            <p>Agora ela é feita diretamente através da página da James Delivery. Clique no link abaixo para seguir com a candidatura.
            </p>
            <br />
            <p>Se você já foi pré-aprovado pela James, fique tranquilo que sua candidatura está sendo processada e fique atento ao seu e-mail!</p>
            <br />
          </div>
          <a href={'https://jamesdelivery.com.br/entregadores'}>Seguir com a James Delivery</a>
        </div>
        <div className="CompanyJobJames__Vojo">
          <h2>Confira outras oportunidades através da página do VOJO.</h2>
          <a href={routes.JOBS_LIST}>
            <ButtonEduk
              retangular
              width={'232px'}
              padding={'10px 20px'}
            >
              <TypographyEduk
                fontSize={'14px'}
                font-weight={'500'}
                line-height={'18px'}
                color={themes.eduk.colors.whiteColor}
                letterSpacing={'1px'}>
                Conferir oportunidades
              </TypographyEduk>
            </ButtonEduk>
          </a>
        </div>
      </Container>
    )
  }
}

export default CompanyJobJames
