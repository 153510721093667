import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const passwordRecover = async (data) => {
  try {
    const response = await VojoAxios.post(apiEndpoints.FORGOT_PASSWORD, data)
    return response
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
