import { call, put, takeLatest } from 'redux-saga/effects'

import JobDetailService from './services'
import types from '../../actions/types'
import { getJobDetail } from './requests'

export function * jobDetailSet (action) {
  try {
    yield put({ type: types.JOB_DETAIL_LOAD })

    const jobService = yield new JobDetailService()
    yield jobService.getJobFromJobList(action.data.slug)
    const jobFromList = jobService.selectedJob
    const jobDetailData = jobService.selectedJob
      ? jobFromList
      : yield call(getJobDetail, action.data)

    yield put({ type: types.JOB_DETAIL_SUCCESS, payload: { data: jobDetailData } })
  } catch (e) {
    yield put({ type: types.JOB_DETAIL_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * jobDetailSaga () {
  yield takeLatest(types.JOB_DETAIL_SET, jobDetailSet)
}
