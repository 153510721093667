import * as SentryService from '@sentry/react'

const isInvalidId = (value) => {
  return [undefined, 'undefined', '', null, 'null', ':id'].includes(value)
}

class Sentry {
  setUser = (user) => {
    SentryService.setContext('user', user)
  }

  setContext = (context) => {
    Object.entries(context).map(([key, value]) => SentryService.setContext(key, value))
  }

  sentryEvent = (type, data) => {
    data.setContext && this.setContext(data.setContext)
    const event = {
      undefinedId: isInvalidId(data?.id) && SentryService.captureException(new Error('Undefined Id '))
    }
    return (event[type])
  }
}

export default Sentry
