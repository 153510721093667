import * as types from './types'

export const jobListLoad = () => ({
  type: types.JOB_LIST_LOAD
})

export const jobListSet = data => ({
  type: types.JOB_LIST_SET,
  data
})

export const jobListError = error => ({
  type: types.JOB_LIST_FAILED,
  error
})

export const jobListSuccess = () => ({
  type: types.JOB_LIST_SUCCESS
})

export const jobListClear = () => ({
  type: types.JOB_LIST_CLEAR
})
