import { combineReducers } from 'redux'

import applicationListReducer from './applicationList/reducer'
import applicationReducer from './application/reducer'
import assessmentReducer from './assessment/reducer'
import authorizationReducer from './authorization/reducer'
import courseReducer from './course/reducer'
import formReducer from './form/reducer'
import jobReducer from './job/reducer'
import jobDetailReducer from './jobDetail/reducer'
import jobListReducer from './jobList/reducer'
import loginReducer from './login/reducer'
import passwordChangeReducer from './passwordChange/reducer'
import passwordRecoverReducer from './passwordRecover/reducer'
import passwordResetReducer from './passwordReset/reducer'
import registerReducer from './register/reducer'
import userDataReducer from './userData/reducer'
import companyReducer from './company/reducer'
import couponReducer from './coupon/reducer'
import gatekeeperReducer from './gatekeeper/reducer'

const rootReducer = combineReducers({
  application: applicationReducer,
  applicationList: applicationListReducer,
  assessment: assessmentReducer,
  authorization: authorizationReducer,
  coupon: couponReducer,
  course: courseReducer,
  form: formReducer,
  jobs: jobReducer,
  jobDetail: jobDetailReducer,
  jobList: jobListReducer,
  passwordChange: passwordChangeReducer,
  passwordRecover: passwordRecoverReducer,
  passwordReset: passwordResetReducer,
  login: loginReducer,
  register: registerReducer,
  user: userDataReducer,
  company: companyReducer,
  gatekeeper: gatekeeperReducer
})

export default rootReducer
