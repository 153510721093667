export const createCategorizedJobLists = (jobList) => {
  const categorizedJobList = {
    availableJobs: [],
    unavailableJobs: []
  }

  for (let i = 0; i < jobList.length; i++) {
    const job = jobList[i]
    if (job.totalSpots === 0) {
      categorizedJobList.unavailableJobs.push(job)
    } else {
      categorizedJobList.availableJobs.push(job)
    }
  }

  return categorizedJobList
}
