import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { getjobList } from './requests'
import { createCategorizedJobLists } from './services'

export function * jobListSet (action) {
  try {
    yield put({ type: types.JOB_LIST_LOAD })
    const jobListData = yield call(getjobList, action.data)
    const categorizedJobList = yield createCategorizedJobLists(jobListData)
    yield put({ type: types.JOB_LIST_SUCCESS, payload: { data: categorizedJobList } })
  } catch (e) {
    yield put({ type: types.JOB_LIST_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * jobListSaga () {
  yield takeLatest(types.JOB_LIST_SET, jobListSet)
}
