import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const authorizateUser = async () => {
  try {
    const response = await VojoAxios.get(apiEndpoints.AUTHORIZATION)
    const authData = response.data.data
    return authData
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
