import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const getjobList = async (data) => {
  try {
    const requestEndpoint = data.isAuth
      ? apiEndpoints.JOB_LIST_AVAILABLE
      : apiEndpoints.JOB_LIST
    const response = await VojoAxios.get(requestEndpoint)
    return response.data.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
