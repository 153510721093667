import axios from 'axios'

import getAuthToken from '../../utils/Authentication/getAuthToken'

const vojoAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

// REQUEST INTERCEPTORS
vojoAxios.interceptors.request.use(config => {
  const authToken = getAuthToken()
  if (authToken) config.headers.Authorization = `Bearer ${authToken}`

  return config
})

// RESPONSE INTERCEPTORS
vojoAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // Here we do global error treatment
    throw error
  }
)

export default vojoAxios
