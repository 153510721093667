import * as types from '../../actions/authorization/types'

const defaultState = {
  data: {},
  isAuth: false,
  isLoading: false,
  error: null,
  success: false
}

const loadAuthorization = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successAuthorization = (state, payload) => ({
  ...state,
  data: payload,
  error: null,
  isAuth: true,
  isLoading: false,
  success: true
})

const successUnauthorization = (state) => ({
  ...state,
  ...defaultState
})

const failAuthorization = (state, payload) => ({
  ...state,
  data: {},
  error: payload.error,
  isAuth: false,
  isLoading: false,
  success: false
})

const authorizationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.AUTHORIZATION_LOAD:
      return loadAuthorization(state)

    case types.AUTHORIZATION_SUCCESS:
      return successAuthorization(state, action.payload)

    case types.AUTHORIZATION_UNSET:
      return successUnauthorization(state)

    case types.AUTHORIZATION_UNSET_SUCCESS:
      return successUnauthorization(state)

    case types.AUTHORIZATION_FAILED:
      return failAuthorization(state, action.payload)

    default:
      return state
  }
}

export default authorizationReducer
