import * as types from './types'

export const jobLoad = () => ({
  type: types.JOB_LOAD
})

export const jobSet = data => ({
  type: types.JOB_SET,
  data
})

export const jobError = error => ({
  type: types.JOB_FAILED,
  error
})

export const jobSuccess = () => ({
  type: types.JOB_SUCCESS
})

export const jobClear = () => ({
  type: types.JOB_CLEAR
})
