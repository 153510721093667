import * as types from './types'

export const jobDetailLoad = () => ({
  type: types.JOB_DETAIL_LOAD
})

export const jobDetailSet = data => ({
  type: types.JOB_DETAIL_SET,
  data
})

export const jobDetailError = error => ({
  type: types.JOB_DETAIL_FAILED,
  error
})

export const jobDetailSuccess = () => ({
  type: types.JOB_DETAIL_SUCCESS
})

export const jobDetailClear = () => ({
  type: types.JOB_DETAIL_CLEAR
})
