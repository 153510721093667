
import * as types from './types'

export const courseLoad = () => ({
  type: types.COURSE_GET_LOAD
})

export const courseSet = data => ({
  type: types.COURSE_GET,
  data
})

export const courseError = error => ({
  type: types.COURSE_GET_FAILED,
  error
})

export const courseSuccess = () => ({
  type: types.COURSE_GET_SUCCESS
})

export const courseSuggestionSet = data => ({
  type: types.COURSE_SUGGESTION_GET,
  data
})
