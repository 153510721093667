export const FORM_SET = 'FORM_SET'
export const FORM_LOAD = 'FORM_LOAD'
export const FORM_SUCCESS = 'FORM_SUCCESS'
export const FORM_FAILED = 'FORM_FAILED'
export const FORM_CLEAR = 'FORM_CLEAR'

export const FORM_SUBMIT_SET = 'FORM_SUBMIT_SET'
export const FORM_SUBMIT_LOAD = 'FORM_SUBMIT_LOAD'
export const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS'
export const FORM_SUBMIT_FAILED = 'FORM_SUBMIT_FAILED'
export const FORM_SUBMIT_CLEAR = 'FORM_SUBMIT_CLEAR'
