import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const updateApplication = async (data) => {
  try {
    const response = await VojoAxios.put(apiEndpoints.APPLICATION(data.applicationId))
    sessionStorage.setItem('applicationId', response.data.data.application.id)
    return response.data.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}

export const submitApplication = async (data) => {
  const params = { jobRoleId: data.jobRoleId }
  try {
    const response = await VojoAxios.post(apiEndpoints.APPLICATION(), { ...params })
    sessionStorage.setItem('applicationId', response.data.data.applicationId)
    return response.data.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}

export const checkRequirements = async (id) => {
  try {
    const response = await VojoAxios.post(apiEndpoints.APPLICATION_CHECK_REQUIREMENTS(id))
    return response.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
