import { call, put, takeLatest } from 'redux-saga/effects'

import removeAuthToken from '../../../utils/Authentication/removeAuthToken'
import types from '../../actions/types'
import { authorizateUser } from './requests'

export function * authorizationSet () {
  try {
    yield put({ type: types.AUTHORIZATION_LOAD })
    const authorizationData = yield call(authorizateUser)
    yield put({ type: types.AUTHORIZATION_SUCCESS, payload: authorizationData })
  } catch (e) {
    yield removeAuthToken()
    yield put({ type: types.AUTHORIZATION_FAILED, payload: { error: e } })
  }
}

export function * authorizationUnset () {
  try {
    yield put({ type: types.AUTHORIZATION_LOAD })
    yield removeAuthToken()
    yield put({ type: types.AUTHORIZATION_UNSET_SUCCESS })
  } catch (e) {
    yield put({ type: types.AUTHORIZATION_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * authorizationSaga () {
  yield takeLatest(types.AUTHORIZATION_SET, authorizationSet)
  yield takeLatest(types.AUTHORIZATION_UNSET, authorizationUnset)
}
