import React from 'react'
import { 
  FormRadioEduk, 
  FormCheckboxEduk, 
  FormInputEduk, 
  FormSelect, 
  FormMultiSelect, 
  TypographyEduk, 
  themes 
} from '@mindlab-org/component-library'

const FieldBuilder = props => {
  /**
  * Add an unique identifier (id) for the object "options"
  * since the original object does not have it
   */
  const createFieldId = (name, value) => `${name}-${value}`

  /*
  * Prepare object for the component
  */
  const buildOptions = (options) => {
    const { field } = props

    return options.map(option => ({
      id: createFieldId(field.name, option.value),
      title: `${option.description}`,
      ...option
    }))
  }

  /**
   * @description Adiciona os componentes de título e descrição de grupo de campos 
   * se houver
   * @param field campo onde vão ser adicionados os componentes do grupo de campos
   * @returns array de elementos JSX a serem renderizados
   */
  const buildGroupOfFields = field => {
    const typographyProps = {
      groupTitle: {
        type: 'title',
        lineHeight: '20px',
        fontSize: '18px',
        tag: 'h2'
      }, 
      groupDescription: {
        type: 'default',
        color: themes.eduk.colors.lightGreyColor,
        lineHeight: '18px',
        className: 'Forms__Form__Field__GroupDescription'
      }, 
      sectionTitle: {
        type: 'h3',
        color: themes.eduk.colors.greyColor,
        className: 'Forms__Form__Field__SectionTitle'
      }
    }

    return Object.entries(typographyProps).map(([key, props]) => 
      field[key] && <TypographyEduk { ...props }>{ field[key] }</TypographyEduk>
    )
  }

  const handlers = {
    radio: () => {
      const {
        errorMessage,
        hasError,
        field,
        onInputChange,
        onToolTipClick,
        value
      } = props

      const hasPreSelectedOption = value && value.value !== null

      return (
        <FormRadioEduk
          id={field.name}
          description={null}
          errorMessage={errorMessage}
          hasError={hasError}
          hasToolTip={field.helpbox}
          onClickOption={(value) => onInputChange && onInputChange(value)}
          onClickToolTip={(value) => onToolTipClick && onToolTipClick(value)}
          options={buildOptions(field.options)}
          preSelectedOption={hasPreSelectedOption ? createFieldId(field.name, value.value) : null}
          title={field.title}
          tooltipData={field.helpbox}
        />
      )
    },

    checkbox: () => {
      const {
        errorMessage,
        hasError,
        field,
        onInputChange,
        onToolTipClick,
        value
      } = props
      const responses = value?.value?.map(opt => createFieldId(field.name, (opt.value !== undefined ? opt.value : opt))) || null
      const hasSelectAll = field.options.find(item => item.code === 'ALL')
      return (
        <FormCheckboxEduk
          id={field.name}
          labelSelectAll={hasSelectAll?.description}
          hasAllOptions={hasSelectAll}
          description={null}
          errorMessage={errorMessage}
          hasError={hasError}
          hasToolTip={field.helpbox}
          onClickOption={(value) => onInputChange && onInputChange(value)}
          onClickToolTip={(value) => onToolTipClick && onToolTipClick(value)}
          options={hasSelectAll ? buildOptions(field.options.filter(item => item.code !== 'ALL')) : buildOptions(field.options)}
          preSelectedOptions={responses}
          title={field.title}
          tooltipData={field.helpbox}
        />
      )
    },

    input: () => {
      const {
        hasError,
        field,
        onInputChange,
        value,
        onBlur
      } = props

      let inputResponse = null

      if (value) {
        switch (field.settings.validator) {
          case 'date':
            inputResponse = value.value?.split('-').reverse().join('-')
            break
          case 'cep':
            inputResponse = value.value?.split('-').join('')
            break
          default:
            inputResponse = value.value === 'null' ? inputResponse : value.value
            break
        }
      }

      const type = field.settings.type === 'password' ? 'password' : 'text'
      const isRequired = !['branchDigit', 'accountDigit'].includes(field.name)
      return (
        <>
          { buildGroupOfFields(field) }
          <FormInputEduk
            id={field.name}
            errorMessage={value?.errorMessage}
            isRequired={field.required}
            label={field.title}
            name={field.name}
            onInputChange={(value) => onInputChange(value, field.name)}
            onInputBlur={field.settings.onBlur ? onBlur : (value) => onInputChange(value, field.name)}
            placeholder={field.title}
            showError={Boolean(value?.value)}
            hasError={hasError}
            validationType={field.settings.validator}
            type={'text'}
            isPassword={type === 'password'}
            value={inputResponse}
          />
        </>
      )
    },

    select: () => {
      const {
        field,
        onInputChange,
        defaultValue,
        hasError,
        errorMessage
      } = props
      const options = field.options.map(item => (field.name !== 'bankCode' ? { value: item.code, label: item.description } : { value: item.code, label: `${item.code} - ${item.description}` }))

      return (
        <FormSelect
          id={field.name}
          hasError={hasError}
          errorMessage={errorMessage}
          options={options}
          title={field.settings.label}
          titleMargin={'15px 0'}
          onSelectChange={onInputChange}
          name={field.name}
          placeholder={field.settings.placeholder}
          defaultSelectValue={defaultValue ? options.find(item => item.value === defaultValue.value) : ''} />
      )
    },

    multiSelect: () => {
      const {
        field,
        onInputChange,
        defaultValue,
        hasError,
        errorMessage, 
        value
      } = props
      const options = field.options.map(({ value, description }) => ({ value, label: description }))
      const isEmpty = value === undefined
      return (
        <FormMultiSelect
          id={field.name}
          hasError={hasError || isEmpty}
          errorMessage={errorMessage}
          options={options}
          title={field.title}
          description={field.settings.label}
          titleMargin={'15px 0'}
          onSelectChange={onInputChange}
          name={field.name}
          value={value?.value}
          placeholder={field.settings.placeholder}
          defaultSelectValue={defaultValue ? options.find(item => item.value === defaultValue.value) : ''} />
      )
    }
  }

  return handlers[props.field.component]()
}

export default FieldBuilder
