import * as applicationListTypes from './applicationList/types'
import * as applicationTypes from './application/types'
import * as assessmentTypes from './assessment/types'
import * as authorizationTypes from './authorization/types'
import * as courseTypes from './course/types'
import * as formTypes from './form/types'
import * as jobTypes from './job/types'
import * as jobDetailTypes from './jobDetail/types'
import * as jobListTypes from './jobList/types'
import * as loginTypes from './login/types'
import * as passwordChangeTypes from './passwordChange/types'
import * as passwordRecoverTypes from './passwordRecover/types'
import * as passwordResetTypes from './passwordReset/types'
import * as registerTypes from './register/types'
import * as userDataTypes from './userData/types'
import * as companyTypes from './company/types'
import * as couponTypes from './coupon/types'
import * as gatekeeperTypes from './gatekeeper/types'

export default {
  ...applicationListTypes,
  ...applicationTypes,
  ...assessmentTypes,
  ...authorizationTypes,
  ...companyTypes,
  ...couponTypes,
  ...courseTypes,
  ...formTypes,
  ...jobTypes,
  ...jobDetailTypes,
  ...jobListTypes,
  ...loginTypes,
  ...passwordChangeTypes,
  ...passwordRecoverTypes,
  ...passwordResetTypes,
  ...registerTypes,
  ...userDataTypes,
  ...gatekeeperTypes
}
