import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import * as routes from '../routes'

const AuthorizedRoute = props => {
  return !props.authorization.isAuth
    ? <Redirect to={{
      pathname: routes.LOGIN,
      state: {redirect: props?.location?.pathname}
      }}/>    
    : <Route {...props}/>
}

const mapStateToProps = state => ({
  authorization: state.authorization
})

export default connect(mapStateToProps)(AuthorizedRoute)
