import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { validateCoupon, getUserCoupons } from './requests'

export function * validateCouponSet (action) {
  try {
    const couponData = yield call(validateCoupon, action.data)
    yield put({ type: types.COUPON_VALIDATE_SUCCESS, data: { data: couponData } })
  } catch (e) {
    yield put({ type: types.COUPON_VALIDATE_FAILED, payload: { error: e } })
  }
}

export function * getCoupon () {
  try {
    const couponData = yield call(getUserCoupons)
    yield put({ type: types.COUPON_GET_SUCCESS, payload: couponData.coupons })
  } catch (error) {
    yield put({ type: types.COURSE_GET_FAILED, payload: { error } })
  }
}

// This will watch for the actions dispatches
export default function * formSaga () {
  yield takeLatest(types.COUPON_VALIDATE_GET, validateCouponSet)
  yield takeLatest(types.COUPON_GET, getCoupon)
}
