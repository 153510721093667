import * as types from './types'

export const formLoad = () => ({
  type: types.FORM_LOAD
})

export const formSet = data => ({
  type: types.FORM_SET,
  data
})

export const formError = error => ({
  type: types.FORM_FAILED,
  error
})

export const formSuccess = () => ({
  type: types.FORM_SUCCESS
})

export const formClear = () => ({
  type: types.FORM_CLEAR
})

export const formSubmitLoad = () => ({
  type: types.FORM_SUBMIT_LOAD
})

export const formSubmitSet = data => ({
  type: types.FORM_SUBMIT_SET,
  data
})

export const formSubmitError = error => ({
  type: types.FORM_SUBMIT_FAILED,
  error
})

export const formSubmitSuccess = () => ({
  type: types.FORM_SUBMIT_SUCCESS
})

export const formSubmitClear = () => ({
  type: types.FORM_SUBMIT_CLEAR
})
