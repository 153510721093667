import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const getjob = async (data) => {
  try {
    const params = {
      role: data.role,
      state: data.state
    }
    const response = await VojoAxios.get(apiEndpoints.JOBS, { params })
    return response.data.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
