import { store } from '../../../App'

class JobDetailService {
  constructor () {
    this.jobList = store.getState().jobList.data.availableJobs
    this.selectedJob = null
  }

  getJobFromJobList = (slug) => {
    this.selectedJob = this.jobList.find(job => job.slug === slug)
  }
}

export default JobDetailService
