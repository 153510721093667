const edukUrl = process.env.REACT_APP_EDUK_URL;

export const routesEduk = {
  changePassword: `${edukUrl}/alterar-senha`,
  favorites: `${edukUrl}/favoritos`,
  forgotPassword: `${edukUrl}/esqueci-senha`,
  home: edukUrl,
  jobList: `${edukUrl}/vagas`,
  login: `${edukUrl}/login`,
  advantages: `${edukUrl}/beneficios`,
  personal: `${edukUrl}/perfil`,
  privacyPolicy: `${edukUrl}/aviso-de-privacidade`,
  register: `${edukUrl}/cadastro`,
  signature: `${edukUrl}/perfil`,
  termOfUse: `${edukUrl}/termos-de-uso`,
  trails: `${edukUrl}/trilhas`,
};
