import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'
import setAuthToken from '../../../utils/Authentication/setAuthToken'

export const loginUser = async (data) => {
  try {
    const response = await VojoAxios.post(apiEndpoints.LOGIN, data, { headers: { 'X-Custom-Header': 'ProcessThisImmediately' } })
    const authToken = response.headers['vojo-authorization']
    setAuthToken(authToken)
    return response
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
