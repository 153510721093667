import * as types from './types'

export const companyGet = companySlug => ({
  type: types.COMPANY_GET,
  companySlug
})

export const companySetLoad = () => ({
  type: types.COMPANY_SET_LOAD
})

export const companySetSuccess = () => ({
  type: types.COMPANY_SET_SUCCESS
})

export const companySetFailed = error => ({
  type: types.COMPANY_SET_FAILED,
  error
})

export const relateUsers = payload => ({
  type: types.ADD_USER,
  payload
})

export const relateUsersLoad = () => ({
  type: types.ADD_USER_LOAD
})

export const relateUsersSuccess = () => ({
  type: types.ADD_USER_SUCCESS
})

export const relateUsersFailed = error => ({
  type: types.ADD_USER_FAILED,
  error
})

export const companyClear = () => ({
  type: types.COMPANY_CLEAR
})
