export const clearSessionStorage = () => {
  window.sessionStorage.clear()
}

export const clearLocalStorage = () => {
  window.localStorage.clear()
}

export default () => {
  clearSessionStorage()
  clearLocalStorage()
}