import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SpinnerEduk } from '@mindlab-org/component-library'

import Intercom from '../services/Intercom'
import { actions } from '../redux/actions/actions'
import getAuthToken from '../utils/Authentication/getAuthToken'
import Sentry from '../services/Sentry'
class AuthorizationLayer extends Component {
  state = {
    isChecking: true
  }

  sentry = new Sentry()
  componentDidMount () {
    this.checkForAuthorization()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.authorization.isLoading === true &&
      this.props.authorization.isLoading === false) {
      this.props.authorization.success && this.sentry.setUser(this.props.authorization.data)
      this.setState({ isChecking: false })
    }

    const intercom = this.startIntercom()

    if (this.props.company.data || sessionStorage.getItem('companySlug')) {
      intercom.hideIntercomButton()
    }
  }

  startIntercom = () => {
    const { authorization } = this.props
    const company = this.props.company?.data
    const intercom = new Intercom()
    const authData = authorization.isAuth
      ? authorization.data
      : null
    intercom.connect(authData, company)
    return intercom
  }

  checkForAuthorization = () => {
    const authToken = getAuthToken()
    if (authToken && !this.props.authorization.isAuth) {
      this.props.authorizationSet()
    } else {
      this.setState({ isChecking: false })
    }
  }

  renderSpinner = () => (
    <SpinnerEduk spinnerArea="100vh"/>
  )

  render () {
    return this.state.isChecking
      ? this.renderSpinner()
      : this.props.children
  }
}

const mapStateToProps = state => ({
  authorization: state.authorization,
  company: state.company
})

const mapDispatchToProps = dispatch => ({
  authorizationSet: () => dispatch(actions.authorizationSet())
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationLayer)
