import * as types from '../../actions/jobDetail/types'

const defaultState = {
  data: {},
  isLoading: false,
  error: null,
  success: false
}

const loadJobDetail = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successJobDetail = (state, payload) => ({
  ...state,
  data: payload.data,
  error: null,
  isLoading: false,
  success: true
})

const failJobDetail = (state, payload) => ({
  ...state,
  data: {},
  error: payload.error,
  isLoading: false,
  success: false
})

const clearJobDetail = () => ({
  ...defaultState
})

const jobDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.JOB_DETAIL_LOAD:
      return loadJobDetail(state)

    case types.JOB_DETAIL_SUCCESS:
      return successJobDetail(state, action.payload)

    case types.JOB_DETAIL_FAILED:
      return failJobDetail(state, action.payload)

    case types.JOB_DETAIL_CLEAR:
      return clearJobDetail()

    default:
      return state
  }
}

export default jobDetailReducer
