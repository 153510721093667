import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { createUser } from './requests'

export function * registerSet (action) {
  try {
    yield put({ type: types.REGISTER_LOAD })
    yield call(createUser, action.data)
    yield put({ type: types.REGISTER_SUCCESS, payload: { data: action.data } })
    yield put({ type: types.AUTHORIZATION_SET })
  } catch (e) {
    yield put({ type: types.REGISTER_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * registerSaga () {
  yield takeLatest(types.REGISTER_SET, registerSet)
}
