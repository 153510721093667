import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const getForm = async (data) => {
  try {
    const response = await VojoAxios.get(apiEndpoints.FORMS(data.formId))
    return response.data.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}

export const submitForm = async (data) => {
  const params = { responses: data.responses }
  try {
    const response = await VojoAxios.post(apiEndpoints.FORMS_RESPONSE(data.formId, data.applicationId), { ...params })
    return response.data.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}

export const updateForm = async (data) => {
  const params = { responses: data.responses }
  try {
    const response = await VojoAxios.put(apiEndpoints.FORMS_RESPONSE(data.formId, data.applicationId), { ...params })
    return response.data.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
