import * as types from '../../actions/form/types'

const defaultState = {
  formFetch: {
    data: {},
    isLoading: false,
    error: null,
    success: false
  },
  formSubmission: {
    data: {},
    isLoading: false,
    error: null,
    success: false
  }
}

const loadForm = (state) => ({
  ...state,
  formFetch: {
    ...state.formFetch,
    isLoading: true
  }
})

const successForm = (state, payload) => ({
  ...state,
  formFetch: {
    ...state.formFetch,
    data: payload.data,
    error: null,
    isLoading: false,
    success: true
  }
})

const failForm = (state, payload) => ({
  ...state,
  formFetch: {
    ...state.formFetch,
    data: {},
    error: payload.error,
    isLoading: false,
    success: false
  }
})

const clearForm = (state) => ({
  ...state,
  formFetch: {
    ...defaultState.formFetch
  }
})

const loadSubmitForm = (state) => ({
  ...state,
  formSubmission: {
    ...state.formSubmission,
    isLoading: true
  }
})

const successSubmitForm = (state, payload) => ({
  ...state,
  formSubmission: {
    ...state.formSubmission,
    data: payload.data,
    error: null,
    isLoading: false,
    success: true
  }
})

const failSubmitForm = (state, payload) => ({
  ...state,
  formSubmission: {
    ...state.formSubmission,
    data: {},
    error: payload.error,
    isLoading: false,
    success: false
  }
})

const clearSubmitForm = (state) => ({
  ...state,
  formSubmission: {
    ...defaultState.formSubmission
  }
})

const formReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FORM_LOAD:
      return loadForm(state)

    case types.FORM_SUCCESS:
      return successForm(state, action.payload)

    case types.FORM_FAILED:
      return failForm(state, action.payload)

    case types.FORM_CLEAR:
      return clearForm(state)

    case types.FORM_SUBMIT_LOAD:
      return loadSubmitForm(state)

    case types.FORM_SUBMIT_SUCCESS:
      return successSubmitForm(state, action.payload)

    case types.FORM_SUBMIT_FAILED:
      return failSubmitForm(state, action.payload)

    case types.FORM_SUBMIT_CLEAR:
      return clearSubmitForm(state)

    default:
      return state
  }
}

export default formReducer
