import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const getAssessment = async (data) => {
  try {
    const response = await VojoAxios.get(apiEndpoints.ASSESSMENT(data.id))
    return response.data.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}

export const setAssessment = async (data) => {
  try {
    const params = {
      formId: data.formId,
      finalScore: data.score
    }
    const response = await VojoAxios.post(apiEndpoints.ASSESSMENT_RESPONSE(data.id, data.applicationId), { ...params })
    return response.data
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
