import React, { Component } from 'react'
import QueryString from 'qs'
import { connect } from 'react-redux'
import {
  Container,
  FooterEduk,
  TypographyEduk,
  StickyToast,
  themes,
  SpinnerEduk
} from '@mindlab-org/component-library'

import './Assessment.sass'
import CustomHeader from '../../components/CustomHeader'

import * as routes from '../../routes/routes'
import { actions } from '../../redux/actions/actions'
import { assessmentTypes } from '../../utils/enums/assessmentTypes'

/**
 * This view's only purpose is to prepare and
 * send the assessment data to backend, then
 * redirect to the Application view
 */
class AssessmentScore extends Component {
  state = {
    error: null
  }

  applicationId = QueryString.parse(this.props.location.search).application_id
  embedElementId = 'form-builder'

  componentDidMount () {
    this.handleScoreToBackend()
  }

  componentDidUpdate (prevProps) {
    const assessment = this.props.assessmentData.currentAssessment
    const prevAssessment = prevProps.assessmentData.currentAssessment

    if (this.assessmentChangedToSuccess(prevAssessment, assessment)) {
      this.isGatekeeper(this.props.assessmentData.currentAssessment) && this.props.gatekeeperResult({
        approved: assessment.data.approved,
        assessmentId: assessment.data.assessmentId
      })
      this.props.history.push(routes.APPLICATION(this.getDataFromQuery()?.applicationId))
    }
  }

  assessmentChangedToSuccess = (prevAssessment, assessment) => {
    return prevAssessment.success !== assessment.success && assessment.success
  }

  componentWillUnmount () {
    this.handleAssessmentClear()
  }

  isGatekeeper = (assessment) => {
    const assessmentFeedback = assessment.data
    return assessmentFeedback.category === assessmentTypes.PORTEIRO
  }

  invalidDataQuery = (dataQuery) => {
    const undefinedValues = [null, 'null', undefined, 'undefined', '', ':id']
    return dataQuery.some(item => undefinedValues.includes(item))
  }

  getDataFromQuery = () => {
    const dataFromQuery = {
      score: QueryString.parse(this.props.location.search)['?score'],
      formId: QueryString.parse(this.props.location.search).form_id,
      applicationId: QueryString.parse(this.props.location.search).application_id,
      assessmentId: QueryString.parse(this.props.location.search).assessment_id,
      userId: this.props.authorization.data.id
    }

    if (this.invalidDataQuery(Object.values(dataFromQuery))) {
      throw Error('Ocorreu um erro ao processar sua pontuação, tente novamente.')
    }

    return dataFromQuery
  }

  cleanScore = (score) => {
    return score.substr(1)
  }

  handleScoreToBackend = () => {
    try {
      const dataFromQuery = this.getDataFromQuery()
      const requestData = {
        score: this.cleanScore(dataFromQuery.score),
        formId: dataFromQuery.formId,
        id: dataFromQuery.assessmentId,
        applicationId: this.applicationId
      }
      this.props.assessmentSet(requestData)
    } catch (error) {
      this.setState({ error: { message: error.message } })
    }
  }

  handleAssessmentClear = () => {
    this.props.assessmentSetClear()
  }

  render () {
    return (
      <Container maxWidth="full">
        <Container maxWidth="full">
          <CustomHeader history={this.props.history} />
          {
            (
              this.state.error ||
              this.props.assessmentData.error
            ) && (
              <div style={{
                display: 'block',
                width: '100%',
                zIndex: '100'
              }}>
                <StickyToast show>
                  <TypographyEduk
                    color={themes.eduk.colors.redColor}
                    tag="span"
                    type="default">
                    {
                      this.state.error
                        ? this.state.error.message
                        : this.props.assessmentData.error.message
                    }
                  </TypographyEduk>
                </StickyToast>
              </div>
            )
          }
        </Container>
        <Container maxWidth="desktop">
          <div className="AssessmentScore__Content">
            <SpinnerEduk />
            <TypographyEduk
              color={themes.eduk.colors.primaryColor}
              fontSize="16px"
              fontWeight="bold">
              Processando os dados
            </TypographyEduk>
          </div>
        </Container>
        <Container maxWidth="full">
          <FooterEduk />
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  assessmentData: state.assessment,
  authorization: state.authorization,
  gatekeeper: state.gatekeeper
})

const mapDispatchToProps = dispatch => ({
  assessmentSet: data => dispatch(actions.assessmentSet(data)),
  assessmentSetClear: () => dispatch(actions.assessmentSetClear()),
  gatekeeperResult: data => dispatch(actions.gatekeeperResult(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentScore)
