import * as types from './types'

export const passwordRecoverLoad = () => ({
  type: types.PASSWORD_RECOVER_LOAD
})

export const passwordRecoverSet = data => ({
  type: types.PASSWORD_RECOVER_SET,
  data
})

export const passwordRecoverError = error => ({
  type: types.PASSWORD_RECOVER_FAILED,
  error
})

export const passwordRecoverSuccess = () => ({
  type: types.PASSWORD_RECOVER_SUCCESS
})

export const passwordRecoverClear = () => ({
  type: types.PASSWORD_RECOVER_CLEAR
})
