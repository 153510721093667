import * as types from '../../actions/assessment/types'

const defaultState = {
  fetchedAssessment: {
    data: null,
    isLoading: false,
    error: null,
    success: false
  },
  currentAssessment: {
    data: null,
    isLoading: false,
    error: null,
    success: false
  }
}

const loadSetAssessment = (state) => ({
  ...state,
  currentAssessment: {
    ...defaultState.currentAssessment,
    isLoading: true
  }
})

const successSetAssessment = (state, payload) => ({
  ...state,
  currentAssessment: {
    data: payload.data,
    error: null,
    isLoading: false,
    success: true
  }
})

const failSetAssessment = (state, payload) => ({
  ...state,
  currentAssessment: {
    data: null,
    error: payload.error,
    isLoading: false,
    success: false
  }
})

const clearSetAssessment = (state) => ({
  ...state,
  currentAssessment: {
    ...defaultState.currentAssessment
  }
})

const loadGetAssessment = (state) => ({
  ...state,
  fetchedAssessment: {
    ...defaultState.fetchedAssessment,
    isLoading: true
  }
})

const successGetAssessment = (state, payload) => ({
  ...state,
  fetchedAssessment: {
    data: payload.data,
    error: null,
    isLoading: false,
    success: true
  }
})

const failGetAssessment = (state, payload) => ({
  ...state,
  fetchedAssessment: {
    data: null,
    error: payload.error,
    isLoading: false,
    success: false
  }
})

const clearGetAssessment = (state) => ({
  ...state,
  fetchedAssessment: {
    ...defaultState.fetchedAssessment
  }
})

const assessmentReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.ASSESSMENT_GET_LOAD:
      return loadGetAssessment(state)

    case types.ASSESSMENT_GET_SUCCESS:
      return successGetAssessment(state, action.payload)

    case types.ASSESSMENT_GET_FAILED:
      return failGetAssessment(state, action.payload)

    case types.ASSESSMENT_GET_CLEAR:
      return clearGetAssessment(state)

    case types.ASSESSMENT_SET_LOAD:
      return loadSetAssessment(state)

    case types.ASSESSMENT_SET_SUCCESS:
      return successSetAssessment(state, action.payload)

    case types.ASSESSMENT_SET_FAILED:
      return failSetAssessment(state, action.payload)

    case types.ASSESSMENT_SET_CLEAR:
      return clearSetAssessment(state)

    default:
      return state
  }
}

export default assessmentReducer
