import * as types from '../../actions/passwordRecover/types'

const defaultState = {
  isLoading: false,
  error: null,
  success: false
}

const loadPasswordRecover = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successPasswordRecover = (state) => ({
  ...state,
  error: null,
  isLoading: false,
  success: true
})

const failPasswordRecover = (state, payload) => ({
  ...state,
  error: payload.error,
  isLoading: false,
  success: false
})

const clearPasswordRecover = () => ({
  ...defaultState
})

const passwordRecoverReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.PASSWORD_RECOVER_LOAD:
      return loadPasswordRecover(state)

    case types.PASSWORD_RECOVER_SUCCESS:
      return successPasswordRecover(state)

    case types.PASSWORD_RECOVER_FAILED:
      return failPasswordRecover(state, action.payload)

    case types.PASSWORD_RECOVER_CLEAR:
      return clearPasswordRecover()

    default:
      return state
  }
}

export default passwordRecoverReducer
