import * as types from './types'

export const registerLoad = () => ({
  type: types.REGISTER_LOAD
})

export const registerSet = data => ({
  type: types.REGISTER_SET,
  data
})

export const registerError = error => ({
  type: types.REGISTER_FAILED,
  error
})

export const registerSuccess = () => ({
  type: types.REGISTER_SUCCESS
})
