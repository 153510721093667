import React from 'react'
import PropTypes from 'prop-types'
import { TypographyEduk, themes } from '@mindlab-org/component-library'
import './MainTitle.sass'
const MainTitle = ({
  title,
  subtitle,
  alignLeft,
  titleColor,
  subtitleColor,
  fontSizeTitle,
  fontSizeSubtitle
}) => {
  return (<>
    <div className={alignLeft ? 'MainTitleContainer' : 'MainTitleContainer__Center'}>
      <div className="MainTitleContainer__Wrapped">
        <TypographyEduk color={titleColor} fontSize={fontSizeTitle} fontWeight={'700'} lineHeight={'32px'} fontFamily={'Montserrat'}>{title}</TypographyEduk>
      </div>
      <div className="MainTitleContainer__Wrapped">
        <TypographyEduk color={subtitleColor} fontSize={fontSizeSubtitle} fontWeight={'400'} lineHeight={'18px'} fontFamily={'Rubik'}>{subtitle}</TypographyEduk>
      </div>
    </div>
  </>)
}

MainTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  alignLeft: PropTypes.bool,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  fontSizeTitle: PropTypes.string,
  fontSizeSubtitle: PropTypes.string
}

MainTitle.defaultProps = {
  titleColor: themes.eduk.colors.blackColor2,
  subtitleColor: themes.eduk.colors.lightBorderColor,
  fontSizeSubtitle: '15px',
  fontSizeTitle: '1.6em'
}

export default MainTitle
