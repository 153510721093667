import { setupI18n } from '@lingui/core'

import enCatalog from './../../locales/en/messages'
import ptBRCatalog from './../../locales/pt-BR/messages'

export const catalogs = {
  en: enCatalog,
  'pt-BR': ptBRCatalog
}

export const locales = [
  'en',
  'pt-BR'
]

export const defaultLocale = 'pt-BR'

export const i18n = setupI18n({ language: defaultLocale })
i18n.load(catalogs)
