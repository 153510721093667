import * as types from '../../actions/passwordReset/types'

const defaultState = {
  data: {},
  isLoading: false,
  error: null,
  success: false
}

const loadPasswordReset = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successPasswordReset = (state, payload) => ({
  ...state,
  data: payload.data,
  error: null,
  isLoading: false,
  success: true
})

const failPasswordReset = (state, payload) => ({
  ...state,
  data: {},
  error: payload.error,
  isLoading: false,
  success: false
})

const clearPasswordReset = () => ({
  ...defaultState
})

const passwordResetReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.PASSWORD_RESET_LOAD:
      return loadPasswordReset(state)

    case types.PASSWORD_RESET_SUCCESS:
      return successPasswordReset(state, action.payload)

    case types.PASSWORD_RESET_FAILED:
      return failPasswordReset(state, action.payload)

    case types.PASSWORD_RESET_CLEAR:
      return clearPasswordReset()

    default:
      return state
  }
}

export default passwordResetReducer
