import React from 'react'
import { Typography, themes } from '@mindlab-org/component-library/dist/index.js'

export default function TypographyHandler (props) {
  const defaultStyle = { width: '100%', textAlign: 'center', marginBottom: '24px' }

  return (
    <div style={{ ...defaultStyle, ...props }}>
      <Typography
        tag={props.tag}
        fontSize={props.fontSize}
        fontWeight={props.fontWeight}
        fontFamily={props.fontFamily}
        lineHeight={props.lineHeight}
        color={themes.eduk.colors[props.color]}
        letterSpacing={props.letterSpacing}
      >
        {props.label}
      </Typography>
    </div>

  )
}
