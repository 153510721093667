import * as routes from '../routes/routes'
import { themes } from '@mindlab-org/component-library'

export default {
  ONBOARDING: {
    title: 'Como podemos te ajudar hoje?',
    subtitle: 'Escolha uma opção para iniciar a sua experiência com o VOJO.',
    headerGoBack: false,
    cards: [
      {
        title: 'Quero oportunidades de renda',
        subtitle: 'Encontre diferentes oportunidades com as nossas empresas parceiras',
        imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/icon_pag_vagas.png',
        redirectRoute: routes.JOBS_LIST
      },
      {
        title: 'Quero gerar renda por conta própria!',
        subtitle: 'Descubra sites de empresas que te ajudam a gerar renda',
        imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/icon_pag_renda.png',
        redirectRoute: routes.JOBS_ENTERPRISING
      },
      {
        title: 'Quero cursos gratuitos para me desenvolver',
        subtitle: 'Desenvolva o seu lado pessoal e profissional e saia na frente',
        titlePremium: 'Quero ver os conteúdos exclusivos do VOJO+',
        imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/icon_pag_cursos.png',
        redirectRoute: routes.COURSES,
        backgroundColorPremium: themes.eduk.colors.primaryColor
      }]
  },
  JOBS_ENTERPRISING: {
    title: 'O que você gostaria de fazer para gerar renda?',
    subtitle: 'Selecione uma opção para descobrir as empresas parceiras que podem te ajudar.',
    headerGoBack: true,
    cards: [
      {
        id: 'mocked-job-1',
        cardMock: true,
        backgroundColor: themes.eduk.colors.whiteColor,
        imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/icone_servico.png',
        isNew: false,
        title: 'Oferecer os meus serviços',
        titleColor: themes.eduk.colors.blueColor,
        subtitle: 'Descubra empresas para oferecer seus serviços',
        subtitleColor: themes.eduk.colors.blackColor,
        typeformUrl: 'pZiCwfDF',
        freelance: true
      },
      {
        id: 'mocked-job-2',
        cardMock: true,
        backgroundColor: themes.eduk.colors.whiteColor,
        imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/icone_venda.png',
        isNew: false,
        title: 'Vender os meus produtos',
        titleColor: themes.eduk.colors.blueColor,
        subtitle: 'Descubra empresas para vender produtos',
        subtitleColor: themes.eduk.colors.blackColor,
        typeformUrl: 'K7vD197k',
        freelance: true
      },
      {
        id: 'mocked-job-3',
        cardMock: true,
        backgroundColor: themes.eduk.colors.whiteColor,
        imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/icone_revenda.png',
        isNew: false,
        title: 'Revender produtos',
        titleColor: themes.eduk.colors.blueColor,
        subtitle: 'Descubra empresas para revender produtos',
        subtitleColor: themes.eduk.colors.blackColor,
        typeformUrl: 'cG6HX0QB',
        freelance: true
      }
    ],
    modal: {
      title: 'Como funciona?',
      description: 'O nosso propósito é te ajudar a gerar na renda, mas é você quem decide como deseja começar!',
      list: ['Escolha a forma como você deseja gerar renda;',
        'Preencha o questionário;',
        'Descubra os sites das empresas parceiras que recomendamos para você;',
        'Decida se você quer começar a gerar renda com a empresa ou se desenvolver antes com os nossos cursos;',
        'Depois é só compartilhar a sua conquiste conosco!']
    },
    researchCard: {
      description: 'Responda a nossa pesquisa e fique por dentro das novidades.',
      title: 'Não achou o que queria?',
      buttonText: 'VER',
      typeformUrl: 'JNwHMw0H'
    }
  },
  JOBS_LIST: {
    title: 'Oportunidades de renda',
    subtitle: 'Selecione a oportunidade que desejar e inicie sua candidatura com uma empresa parceira da Eduk.',
    headerGoBack: true,
    cards: [
      /* {
        id: 'click-entregas',
        cardMock: true,
        backgroundColor: themes.eduk.colors.whiteColor,
        imageUrl: 'https://static.vojo.com.br/prd/images/click_entregas_logo.svg',
        isNew: false,
        title: 'Entregador',
        description: 'Click Entregas',
        typeformUrl: 'OPl1mRHA',
        freelance: false,
        slotsOn: true,
        locationsOn: true,
        showCountry: false,
        borderRadius: '50%',
        locations: [
          {
            states: ['BH', 'RJ'],
            country: 'brasil'
          }
        ]
      } */
    ],
    modal: {
      title: 'Como funciona?',
      description: 'Nosso objetivo é te ajudar a chegar na renda.',
      list: ['Selecione uma oportunidade;',
        'Preencha o formulário com os dados solicitados;',
        'Faça o teste para traçarmos o seu perfil;',
        'Aguarde pela análise da sua candidatura;',
        'E receba o contato da empresa responsável pelo seu telefone e/ou e-mail.']
    },
    researchCard: {
      description: 'Responda a nossa pesquisa e fique por dentro das novidades.',
      title: 'Não achou o que queria?',
      buttonText: 'VER',
      typeformUrl: 'JNwHMw0H'
    }
  },
  COUPONS: {
    title: 'Meus cupons',
    subtitle: 'Insira o seu cupom para aproveitar os benefícios exclusivos de uma conta VOJO+',
    headerGoBack: false
  },
  COURSES: {
    title: 'Meus cursos',
    titlePremium: 'Meus cursos',
    subtitlePremiun: 'No VOJO+ você confere cursos exclusivos e gratuitos, produzidos pelos melhores profissionais da área.',
    subtitle: 'Melhore o seu desenvolvimento profissional e pessoal de forma prática e eficiente.',
    headerGoBack: true,
    section: [
      {
        title: 'Cursos profissionalizantes',
        cards: [{
          title: 'Aposte em você e se desenvolva na área!',
          imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/icone_curso_edu.png',
          subtitle: 'Confira a lista de cursos disponíveis',
          subtitleColor: themes.eduk.colors.greyColor,
          redirectUrl: 'https://vojo.com.br/cursos/'
        }],
        premiumCards: [
          /* {
            id: 8,
            title: 'VOJO',
            coursesAvailable: 1
          },
          {
            id: 9,
            title: 'Gastronomia',
            coursesAvailable: 3,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/gastronomia.PNG'
          }, */
          {
            id: 10,
            title: 'Manicure',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/manicure.PNG'
          },
          {
            id: 12,
            title: 'Maquiador(a)',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/maquiador.PNG'
          },
          {
            id: 23,
            title: 'Cabeleireiro(a)',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/cabeleireira.PNG'
          },
          {
            id: 24,
            title: 'Esteticista',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/esteticista.PNG'
          },
          {
            id: 14,
            title: 'Diarista',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/diarista.PNG'
          },
          {
            id: 15,
            title: 'Cozinheiro(a)',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/cozinheira.PNG'
          },
          {
            id: 16,
            title: 'Confeiteiro(a)',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/confeiteiro.PNG'
          },
          {
            id: 17,
            title: 'Mecânico',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/mecanico.PNG'
          },
          {
            id: 18,
            title: 'Costureiro(a)',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/costureiro.PNG'
          },
          {
            id: 19,
            title: 'Marceneiro',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/marceneiro.PNG'
          },
          {
            id: 20,
            title: 'Artesão',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/artesao.PNG'
          },
          {
            id: 21,
            title: 'Empreendedorismo',
            coursesAvailable: 2,
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/empreendedor.PNG'
          }
        ]
      },
      {
        // title: 'Habilidades e competências',
        // cards: [{
        //   title: 'O poder do autoconhecimento',
        //   imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/icone_curso_vojo.png',
        //   redirectRoute: routes.JOBS_ENTERPRISING,
        //   workload: '60 min.',
        //   slug: 'cursovojo'
        // }],
        premiumCards: [
          {
            title: 'Foca no poder do foco',
            redirectUrl: 'https://meuvojo.thinkific.com/courses/opoderdofoco',
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/foca.PNG',
            workload: '30 min'
          },
          {
            title: 'Organização para a vida',
            redirectUrl: 'https://meuvojo.thinkific.com/courses/organizacao',
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/organizacao.PNG',
            workload: '30 min'
          },
          {
            title: 'Como se tornar um Sherlock Homes?',
            redirectUrl: 'https://meuvojo.thinkific.com/courses/mundodotrabalho',
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/detetive.PNG',
            workload: '30 min'
          },
          {
            title: 'Par ou ímpar - O trabalho em equipe',
            redirectUrl: 'https://meuvojo.thinkific.com/courses/equipe',
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/parimpar.PNG',
            workload: '30 min'
          },
          {
            title: 'Como tomar a decisão certa?',
            redirectUrl: 'https://meuvojo.thinkific.com/courses/mesadosucesso',
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/direcao.PNG',
            workload: '30 min'
          },
          {
            title: 'Equilíbrio é tudo!',
            redirectUrl: 'https://meuvojo.thinkific.com/courses/habilidade',
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/equilibro.PNG',
            workload: '30 min'
          },
          {
            title: 'Um passo de cada vez',
            redirectUrl: 'https://meuvojo.thinkific.com/courses/etapas',
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/escada.PNG',
            workload: '30 min'
          },
          {
            title: 'Conheça a si próprio',
            redirectUrl: 'https://meuvojo.thinkific.com/courses/autoconhecimento',
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/autoconhecimento.PNG',
            workload: '30 min'
          },
          {
            title: 'Errando e aprendendo',
            redirectUrl: 'https://meuvojo.thinkific.com/courses/vencernavida',
            imageUrl: 'https://s3.amazonaws.com/static.vojo.com.br/prd/images/erro.PNG',
            workload: '30 min'
          }
        ]
      }
    ]
  }
}
