import * as types from '../../actions/jobList/types'

const defaultState = {
  data: {
    availableJobs: [],
    unavailableJobs: []
  },
  isLoading: false,
  error: null,
  success: false
}

const loadJobList = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successJobList = (state, payload) => ({
  ...state,
  data: payload.data,
  error: null,
  isLoading: false,
  success: true
})

const failJobList = (state, payload) => ({
  ...state,
  data: {
    availableJobs: [],
    unavailableJobs: []
  },
  error: payload.error,
  isLoading: false,
  success: false
})

const clearJobList = () => ({
  ...defaultState
})

const jobListReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.JOB_LIST_LOAD:
      return loadJobList(state)

    case types.JOB_LIST_SUCCESS:
      return successJobList(state, action.payload)

    case types.JOB_LIST_FAILED:
      return failJobList(state, action.payload)

    case types.JOB_LIST_CLEAR:
      return clearJobList()

    default:
      return state
  }
}

export default jobListReducer
