import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  FooterEduk,
  SpinnerEduk,
  TypographyEduk,
  IconManager,
  TextInput,
  ButtonEduk,
  Snackbar,
  ModalInformationEduk,
  themes
} from '@mindlab-org/component-library'
import './Coupons.sass'
import MainTitle from '../../components/MainTitle'
import CustomHeader from '../../components/CustomHeader'
import { actions } from '../../redux/actions/actions'
import writingPages from '../../data/writingPages'
import * as routes from '../../routes/routes'

class Coupons extends Component {
  state = {
    cuponInput: ''
  }

  async componentDidMount () {
    const { isAuth } = this.props.authorization
    await this.props.jobListSet({ isAuth })
    this.props.couponGet()
  }

  renderSpinner = () => (
    <SpinnerEduk
      spinnerArea="50vh"
    />
  )

  updateCupon = (cuponInput) => {
    this.setState({ cuponInput })
  }

  validateCupon = (event) => {
    event.preventDefault()
    this.props.couponValidate(this.state.cuponInput)
  }

  goToOnboarding = (event) => {
    event.preventDefault()
    this.props.resetSuccess()
    this.props.history.push(routes.ONBOARDING)
  }

  formatDate = (date) => {
    return new Intl.DateTimeFormat('pt-BR').format(new Date(date))
  }

  renderCoupons = () => {
    return this.props.couponData?.data[0]?.id !== undefined &&
      <div className="Coupons__Section__validatedCoupons">
        {this.props.couponData?.data.map((coupon) => {
          return <Snackbar
            key={coupon.id}
            iconName={'Coupon'}
            title={`${coupon.code}`}
            description={`válido até ${this.formatDate(coupon.validUntil)}`}
            buttonText={'VER'}
            backgroundColor={themes.eduk.colors.whiteColor}
            iconColor={themes.eduk.colors.primaryColor}
            descriptionColor={themes.eduk.colors.lightGreyColor}
            titleColor={themes.eduk.colors.blackColor2Color}
            buttonTextColor={themes.eduk.colors.primaryColor}
            onClickSnackbar={() => this.props.history.push(routes.ONBOARDING)} />
        })
        }
      </div>
  }

  closeError () {
    this.props.closeError()
  }

  renderCouponMessageContainer = () => {
    return this.props.couponData?.data[0]?.id !== undefined &&
      <Container maxWidth="full">
        <div className="CouponMessageTitle">
          <TypographyEduk type={'h3'}>Cupons validados!</TypographyEduk>
          <div className="CouponMessageSubTitle">
            <TypographyEduk color={themes.eduk.colors.greyColor} fontSize={'14px'}>Selecione abaixo para acessar os conteúdos exclusivos do VOJO+
            </TypographyEduk>
          </div>
        </div>
      </Container>
  }

  renderCouponMessageError = () => {
    return this.props.couponData.error !== null && <div className="Coupons__Section__validatedCoupons">
      <Snackbar
        description={this.props.couponData.error || ''}
        buttonText={'OK'}
        backgroundColor={themes.eduk.colors.pinkColor}
        descriptionColor={themes.eduk.colors.whiteColor}
        descriptionFontSize={'16px'}
        buttonTextColor={themes.eduk.colors.whiteColor}
        onClickSnackbar={() => this.closeError()} />
    </div>
  }

  render () {
    if (this.props.couponData.isLoading && this.props.jobList.isLoading) return this.renderSpinner()
    return (
      <Container maxWidth="full">
        {this.props?.couponData?.showModal && <ModalInformationEduk
          modalWidth={'280px'}
          icon={
            <div className='ModalIcon'>
              <IconManager width={'83px'} height={'83px'} fill={themes.eduk.colors.greenColor} icon={'CheckOutlined'} />
            </div>}
          title={'Cupom validado!'}
          description={'Aproveite todos os conteúdos exclusivos em sua nova área do VOJO+'}
          actions={<ButtonEduk retangular={true} onButtonClick={this.goToOnboarding}>INICIAR EXPERIÊNCIA</ButtonEduk>} />}
        <Container maxWidth="full">
          <CustomHeader goBack={true} history={this.props.history} />
        </Container>
        <div className="MinHeight">
          <Container maxWidth="full">
            <div className="Coupons">
              <div className="Coupons__Icon">
                <IconManager icon={'Coupon'} width={'42px'} height={'42px'} />
              </div>
              <MainTitle title={writingPages.COUPONS.title} subtitle={writingPages.COUPONS.subtitle} />
              <div className="Coupons__Section">
                <div className="Coupons__Section__Form">
                  <TextInput
                    label={'Insira seu cupom'}
                    value={this.state.cuponInput}
                    onInputChange={event => this.updateCupon(event.value)}
                    onInputBlur={event => this.updateCupon(event.value)}
                  />
                  <div className="Coupons__Section__Form__Button">
                    <ButtonEduk retangular={true} onButtonClick={event => this.validateCupon(event)}>VALIDAR</ButtonEduk>
                  </div>
                </div>
                {this.renderCouponMessageError()}
                {this.renderCouponMessageContainer()}
                {this.renderCoupons()}
              </div>
            </div>
          </Container>
          {this.props.couponData?.data[0]?.id === undefined && <Container maxWidth="full">
            <div className="CoursesFree">
              <TypographyEduk type={'h3'}>Não possui um cupom?</TypographyEduk>
              <div className="CoursesFree__Wrapper">
                <TypographyEduk color={themes.eduk.colors.greyColor} fontSize={'14px'}>Sem problemas! Você ainda poderá aproveitar os conteúdos gratuitos do VOJO.</TypographyEduk>
              </div>
              <div className="CoursesFree__Button">
                <ButtonEduk
                  retangular={true}
                  textColor={themes.eduk.colors.primaryColor}
                  borderColor={themes.eduk.colors.primaryColor}
                  backgroundColor={themes.eduk.colors.whiteColor}
                  hoverColor={themes.eduk.colors.whiteColor}
                  onButtonClick={() => this.props.history.push(routes.ONBOARDING)}>VER OPORTUNIDADES</ButtonEduk>
              </div>
            </div>
          </Container>}

        </div>
        <Container maxWidth="full">
          <FooterEduk />
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  authorization: state.authorization,
  jobList: state.jobList,
  course: state.course,
  couponData: state.coupon
})

const mapDispatchToProps = dispatch => ({
  jobListSet: data => dispatch(actions.jobListSet(data)),
  courseSet: data => dispatch(actions.courseSet(data)),
  couponValidate: data => dispatch(actions.couponValidate(data)),
  resetSuccess: data => dispatch(actions.couponResetSuccess(data)),
  closeError: () => dispatch(actions.couponCloseError()),
  couponGet: () => dispatch(actions.couponLoad())
})

export default connect(mapStateToProps, mapDispatchToProps)(Coupons)
