import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { getAssessment, setAssessment } from './requests'

export function * assessmentGet (action) {
  try {
    yield put({ type: types.ASSESSMENT_GET_LOAD })
    const assessmentData = yield call(getAssessment, action.data)
    yield put({ type: types.ASSESSMENT_GET_SUCCESS, payload: { data: assessmentData } })
  } catch (e) {
    yield put({ type: types.ASSESSMENT_GET_FAILED, payload: { error: e } })
  }
}
export function * assessmentSet (action) {
  try {
    yield put({ type: types.ASSESSMENT_SET_LOAD })
    const assessmentData = yield call(setAssessment, action.data)
    yield put({ type: types.ASSESSMENT_SET_SUCCESS, payload: { data: assessmentData } })
  } catch (e) {
    yield put({ type: types.ASSESSMENT_SET_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * assessmentSaga () {
  yield takeLatest(types.ASSESSMENT_GET, assessmentGet)
  yield takeLatest(types.ASSESSMENT_SET, assessmentSet)
}
