import * as types from '../../actions/applicationList/types'

const defaultState = {
  data: [],
  isLoading: false,
  error: null,
  success: false
}

const loadApplicationList = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successApplicationList = (state, payload) => ({
  ...state,
  data: payload.data,
  error: null,
  isLoading: false,
  success: true
})

const failApplicationList = (state, payload) => ({
  ...state,
  data: [],
  error: payload.error,
  isLoading: false,
  success: false
})

const clearApplicationList = () => ({
  ...defaultState
})

const applicationListReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.APPLICATION_LIST_GET_LOAD:
      return loadApplicationList(state)

    case types.APPLICATION_LIST_GET_SUCCESS:
      return successApplicationList(state, action.payload)

    case types.APPLICATION_LIST_GET_FAILED:
      return failApplicationList(state, action.payload)

    case types.APPLICATION_LIST_GET_CLEAR:
      return clearApplicationList()

    default:
      return state
  }
}

export default applicationListReducer
