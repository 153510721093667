import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { passwordRecover } from './requests'

export function * passwordRecoverSet (action) {
  try {
    yield put({ type: types.PASSWORD_RECOVER_LOAD })
    yield call(passwordRecover, action.data)
    yield put({ type: types.PASSWORD_RECOVER_SUCCESS })
  } catch (e) {
    yield put({ type: types.PASSWORD_RECOVER_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * passwordRecoverSaga () {
  yield takeLatest(types.PASSWORD_RECOVER_SET, passwordRecoverSet)
}
