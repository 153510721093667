import * as types from './types'

export const loginLoad = () => ({
  type: types.LOGIN_LOAD
})

export const loginSet = data => ({
  type: types.LOGIN_SET,
  data
})

export const loginError = error => ({
  type: types.LOGIN_FAILED,
  error
})

export const loginSuccess = () => ({
  type: types.LOGIN_SUCCESS
})
