import React from 'react'
import './FeedbackMessage.sass'

const FeedbackMessage = ({ children }) => {
  return (
    <div className='FeedbackMessage'>
      {children}
    </div>)
}

export default FeedbackMessage
