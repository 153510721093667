import * as types from '../../actions/application/types'

const defaultState = {
  application: {
    data: {},
    isLoading: false,
    error: null,
    success: false
  },
  applicationCreated: {
    data: {},
    isLoading: false,
    error: null,
    success: false
  },
  checkRequirements: {
    data: {},
    isLoading: false,
    error: null,
    success: false
  }
}

const loadUpdateApplication = (state) => ({
  ...state,
  application: {
    ...state.application,
    isLoading: true
  }
})

const successUpdateApplication = (state, payload) => ({
  ...state,
  application: {
    ...state.application,
    data: payload.data,
    error: null,
    isLoading: false,
    success: true
  }
})

const failUpdateApplication = (state, payload) => ({
  ...state,
  application: {
    ...state.application,
    data: {},
    error: payload.error,
    isLoading: false,
    success: false
  }
})

const clearUpdateApplication = (state) => ({
  ...state,
  application: {
    ...defaultState.application
  }
})

const loadSubmitApplication = (state) => ({
  ...state,
  applicationCreated: {
    ...state.applicationCreated,
    isLoading: true
  }
})

const successSubmitApplication = (state, payload) => ({
  ...state,
  applicationCreated: {
    ...state.applicationCreated,
    data: payload.data,
    error: null,
    isLoading: false,
    success: true
  }
})

const failSubmitApplication = (state, payload) => ({
  ...state,
  applicationCreated: {
    ...state.applicationCreated,
    data: {
      applicationId: payload.error.applicationId
    },
    error: payload.error,
    isLoading: false,
    success: false
  }
})

const clearApplication = (state) => ({
  ...defaultState
})

const clearApplicationData = (state) => ({
  ...state,
  application: {
    ...defaultState.application
  }
})

const clearSubmitApplication = (state) => ({
  ...state,
  applicationCreated: {
    ...defaultState.applicationCreated
  }
})

const loadCheckApplication = (state) => ({
  ...state,
  checkRequirements: {
    ...state.checkRequirements,
    isLoading: true
  }
})

const successCheckApplication = (state, payload) => ({
  ...state,
  checkRequirements: {
    ...state.checkRequirements,
    data: payload.data,
    error: null,
    isLoading: false,
    success: true
  }
})

const failCheckApplication = (state, payload) => ({
  ...state,
  checkRequirements: {
    ...state.checkRequirements,
    data: {
      applicationId: payload.error.applicationId
    },
    error: payload.error,
    isLoading: false,
    success: false
  }
})

const clearCheckApplication = (state) => ({
  ...state,
  checkRequirements: {
    ...defaultState.checkRequirements
  }
})

const applicationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.APPLICATION_UPDATE_LOAD:
      return loadUpdateApplication(state)

    case types.APPLICATION_UPDATE_SUCCESS:
      return successUpdateApplication(state, action.payload)

    case types.APPLICATION_UPDATE_FAILED:
      return failUpdateApplication(state, action.payload)

    case types.APPLICATION_UPDATE_CLEAR:
      return clearUpdateApplication(state)

    case types.APPLICATION_SUBMIT_LOAD:
      return loadSubmitApplication(state)

    case types.APPLICATION_SUBMIT_SUCCESS:
      return successSubmitApplication(state, action.payload)

    case types.APPLICATION_SUBMIT_FAILED:
      return failSubmitApplication(state, action.payload)

    case types.APPLICATION_SUBMIT_CLEAR:
      return clearSubmitApplication(state)

    case types.APPLICATION_CLEAR:
      return clearApplication(state)

    case types.APPLICATION_DATA_CLEAR:
      return clearApplicationData(state)

    case types.APPLICATION_CHECK_LOAD:
      return loadCheckApplication(state)

    case types.APPLICATION_CHECK_SUCCESS:
      return successCheckApplication(state, action.payload)

    case types.APPLICATION_CHECK_FAILED:
      return failCheckApplication(state, action.payload)

    case types.APPLICATION_CHECK_CLEAR:
      return clearCheckApplication(state)

    default:
      return state
  }
}
export default applicationReducer
