import ModuleBuilder from './ModuleBuilder'

class ModulesArranger {
  constructor (modules) {
    this.modules = modules
    this.incompletedModules = []
    this.completedModules = []
    this.allModules = []
  }

  /**
   * @description Função recebe os dados de Modules, adiciona a allModules e separa entre completed e imcompleted.
   */
  arrangeModules = () => {
    for (let i = 0; i < this.modules.length; i++) {
      const currentModule = this.modules[i]
      const builtModule = new ModuleBuilder(currentModule).buildFinalModule()
      this.allModules.push(builtModule)

      if (
        !builtModule.isCompleted ||
        (builtModule.hasBlockedMessages && !builtModule.isWarn)
      ) {
        this.incompletedModules.push(builtModule)
      } else {
        this.completedModules.push(builtModule)
      }
    }
  }
}

export default ModulesArranger
