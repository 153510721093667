import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'

export const passwordChange = async (data) => {
  try {
    const response = await VojoAxios.post(apiEndpoints.CHANGE_PASSWORD, data)
    return response
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
