import * as routes from '../../routes/routes'
import { themes } from '@mindlab-org/component-library'

class ModuleBuilder {
  constructor (mod) {
    this.mod = mod
    this.cardConfigurations = [
      {
        isCompleted: false,
        hasBlockedMessages: false,
        iconName: 'Pencil',
        iconBackgroundColor: themes.eduk.colors.neutralBlue
      },
      {
        isCompleted: true,
        hasBlockedMessages: false,
        iconName: 'Check',
        iconBackgroundColor: themes.eduk.colors.greenColor
      },
      {
        isCompleted: false,
        hasBlockedMessages: true,
        iconName: 'Exclamation',
        iconBackgroundColor: themes.eduk.colors.redColor
      },
      {
        isCompleted: true,
        hasBlockedMessages: true,
        iconName: 'Check',
        iconBackgroundColor: themes.eduk.colors.greenColor
      }
    ]
  }

  /**
   * @description Retorna um booleano para condição isCompleted
   * @returns {Booolean}
   */
  isCompleted = () => {
    return Boolean(this.mod.completed)
  }

  /**
   * @description Retorna um booleano para condição isEditable
   * @returns {Booolean}
   */
  isEditable = () => {
    const editable = this.mod.editable

    if (!this.isCompleted()) {
      return true
    } else if (this.isCompleted() && editable) {
      return true
    } else {
      return false
    }
  }

  /**
   * @description Retorna um booleano para condição hasBlockedMessages
   * @returns {Booolean}
   */
  hasBlockedMessages = () => {
    return Boolean(this.mod.blockedMessages.length > 0)
  }

  /**
   * @description Retorna true se o modúlo estiver completo e existir mensagens de erro
   * @returns {Booolean}
   */
  isWarn = () => {
    return Boolean(this.isCompleted() && this.hasBlockedMessages())
  }

  /**
   *
   * @returns Configuração do card
   */
  getCardConfigurations = () => {
    return this.cardConfigurations.find(
      cardConfiguration => (
        cardConfiguration.hasBlockedMessages === this.hasBlockedMessages() &&
        cardConfiguration.isCompleted === this.isCompleted()
      )
    )
  }

  /**
   *
   * @returns URL de redirecionamento
   */
  buildRedirectionUrl = () => {
    if (this.mod.type === 'FORM') {
      return routes.FORMS(this.mod.id)
    } else if (this.mod.type === 'ASSESSMENT') {
      return routes.ASSESSMENT(this.mod.id)
    }
  }

  /**
   *
   * @returns configurações finais do Módulo
   */
  buildFinalModule = () => {
    return {
      module: this.mod,
      cardConfigurations: this.getCardConfigurations(),
      hasBlockedMessages: this.hasBlockedMessages(),
      isCompleted: this.isCompleted(),
      isEditable: this.isEditable(),
      isWarn: this.isWarn(),
      redirectUrl: this.buildRedirectionUrl()
    }
  }
}

export default ModuleBuilder
