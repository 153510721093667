/**
 * @description verifica se o valor é diferente de undefined|null|[]
 * @param {string} name - nome do campo
 * @param {any} value
 * @returns **true** se o valor é igual undefined|null|[], e **false** caso contrário
 */

const isInvalid = (value) => {
  if (Array.isArray(value)) return (value.length === 0)

  return [undefined, null, 'null', ''].includes(value)
}

const isFieldEmpty = (name, field, requiredFields) => {
  if (!field) return true
  if (!requiredFields.includes(name)) return false

  const value = Array.isArray(field) ? field : field.value

  const isAccountOrBranch = ['branchDigit', 'accountDigit'].includes(name)

  if (isAccountOrBranch) return false

  return (isInvalid(value))
}

/**
 * @description verifica se os campos estão preenchidos
 * @param {object} fields
 * @returns {string[]} os nomes dos campos que não estão preenchidos
 */
export default (fields, requiredFields = []) => {
  return Object.entries(fields)
    .filter(([fieldName, field]) => isFieldEmpty(fieldName, field, requiredFields))
    .map(([fieldName]) => fieldName)
}
