import { routesEduk } from '../../routes/routesEduk'

const menuItemsLoggedOut = [
  {
    text: 'Início',
    func: () => window.open(routesEduk.home, '_self')
  },
  {
    text: 'Trilhas para a renda',
    func: () => window.open(routesEduk.trails, '_self')
  },
  {
    text: 'Oportunidades',
    func: () => window.open(routesEduk.jobList, '_self')
  },
  {
    text: 'Parcerias e benefícios',
    func: () => window.open(routesEduk.advantages, '_self')
  }
]

const menuItemsLogged = (onClickLogout) => [
  ...menuItemsLoggedOut,
  {
    dropdown: true,
    text: 'Minha Conta',
    dropItems: [
      {
        text: 'Favoritos',
        func: () => window.open(routesEduk.favorites)
      },
      {
        text: 'Dados Pessoais',
        func: () => window.open(routesEduk.personal)
      },
      {
        text: 'Assinatura',
        func: () => window.open(routesEduk.personal)
      },
      {
        text: 'Sair',
        func: onClickLogout
      }
    ]
  }
]

export const menuItems = ({ onClickLogout, isAuth }) =>
  isAuth ? menuItemsLogged(onClickLogout) : menuItemsLoggedOut
