import { ButtonEduk, themes, Typography } from '@mindlab-org/component-library/dist/index.js'
import React from 'react'
const ButtonHandler = (props) => {
  return (
    <a href={props.url} style={{ ...props }}>
      <ButtonEduk
        backgroundColor={themes.eduk.colors[props.backgroundColor]}
        borderColor={themes.eduk.colors[props.borderColor]}
        retangular={props.retangular}
        hoverColor={props.hoverColor}
        width={props.width}
        heigth={props.heigth}
        padding={props.padding}
      >
        <Typography
          tag={'span'}
          fontWeigth={props.fontWeigth}
          fontFamily={props.fontFamily}
          fontSize={props.fontSize}
          letterSpacing={props.letterSpacing}
          color={themes.eduk.colors[props.textColor]}
        >
          {props.label}
        </Typography>
      </ButtonEduk>
    </a>
  )
}

export default ButtonHandler
