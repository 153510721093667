import * as types from './types'

export const userDataGet = () => ({
  type: types.USER_DATA_GET
})

export const userDataGetLoad = () => ({
  type: types.USER_DATA_GET_LOAD
})

export const userDataGetSuccess = () => ({
  type: types.USER_DATA_GET_SUCCESS
})

export const userDataGetError = error => ({
  type: types.USER_DATA_GET_FAILED,
  error
})

export const userDataSet = payload => ({
  type: types.USER_DATA_SET,
  payload
})

export const userDataSetLoad = () => ({
  type: types.USER_DATA_SET_LOAD
})

export const userDataSetSuccess = () => ({
  type: types.USER_DATA_SET_SUCCESS
})

export const userDataSetError = error => ({
  type: types.USER_DATA_SET_FAILED,
  error
})
