import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { getApplicationList } from './requests'

export function * applicationListGet (action) {
  try {
    yield put({ type: types.APPLICATION_LIST_GET_LOAD })
    const applicationListData = yield call(getApplicationList, action.data)
    yield put({ type: types.APPLICATION_LIST_GET_SUCCESS, payload: { data: applicationListData } })
  } catch (e) {
    yield put({ type: types.APPLICATION_LIST_GET_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * applicationListSaga () {
  yield takeLatest(types.APPLICATION_LIST_GET, applicationListGet)
}
