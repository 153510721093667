import { call, put, takeLatest } from 'redux-saga/effects'

import types from '../../actions/types'
import { getjob } from './requests'

export function * jobSet (action) {
  try {
    yield put({ type: types.JOB_LOAD })
    const jobData = yield call(getjob, action.data)
    yield put({ type: types.JOB_SUCCESS, payload: { data: jobData } })
  } catch (e) {
    yield put({ type: types.JOB_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * jobSaga () {
  yield takeLatest(types.JOB_SET, jobSet)
}
