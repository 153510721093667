import React from 'react'
import { Provider } from 'react-redux'
import { I18nProvider } from '@lingui/react'
import { ThemeProvider } from '@mindlab-org/component-library'
import HttpsRedirect from 'react-https-redirect'

import AppRouter from './routes/AppRouter'
import AuthorizationLayer from './hoc/AuthorizationLayer'

import { i18n, catalogs, defaultLocale, locales } from './settings/i18n/i18n'
import { setUtmToStorage } from './utils/utm/utmManager'
import configureStore from './redux/store'

import './style/style.sass'

export const store = configureStore()

function App () {
  setUtmToStorage()
  return (
    <HttpsRedirect>
      <Provider store={store}>
        <I18nProvider
          i18n={i18n}
          catalogs={catalogs}
          locales={locales}
          language={defaultLocale}>
          <ThemeProvider>
            <AuthorizationLayer>
              <AppRouter/>
            </AuthorizationLayer>
          </ThemeProvider>
        </I18nProvider>
      </Provider>
    </HttpsRedirect>
  )
}

export default App
