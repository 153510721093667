import * as types from './types'

// GETS
export const assessmentGetLoad = () => ({
  type: types.ASSESSMENT_GET_LOAD
})

export const assessmentGet = data => ({
  type: types.ASSESSMENT_GET,
  data
})

export const assessmentGetError = error => ({
  type: types.ASSESSMENT_GET_FAILED,
  error
})

export const assessmentGetSuccess = () => ({
  type: types.ASSESSMENT_GET_SUCCESS
})

export const assessmentGetClear = () => ({
  type: types.ASSESSMENT_GET_CLEAR
})

// SETS
export const assessmentSetLoad = () => ({
  type: types.ASSESSMENT_SET_LOAD
})

export const assessmentSet = data => ({
  type: types.ASSESSMENT_SET,
  data
})

export const assessmentSetError = error => ({
  type: types.ASSESSMENT_SET_FAILED,
  error
})

export const assessmentSetSuccess = () => ({
  type: types.ASSESSMENT_SET_SUCCESS
})

export const assessmentSetClear = () => ({
  type: types.ASSESSMENT_SET_CLEAR
})
