import VojoAxios from '../../../settings/axios/VojoAxios'
import * as apiEndpoints from '../../../settings/apiEndpoints/vojoApi'
import setAuthToken from '../../../utils/Authentication/setAuthToken'

export const passwordReset = async (data) => {
  try {
    const headers = { Authorization: `Bearer ${data.token}` }
    const response = await VojoAxios.post(apiEndpoints.RESET_PASSWORD, null, { headers })
    const authToken = response.headers['vojo-authorization']
    setAuthToken(authToken)
    return authToken
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
