import * as applicationListActions from './applicationList/actions'
import * as applicationActions from './application/actions'
import * as assessmentActions from './assessment/actions'
import * as authorizationActions from './authorization/actions'
import * as courseActions from './course/actions'
import * as formActions from './form/actions'
import * as jobActions from './job/actions'
import * as jobDetailActions from './jobDetail/actions'
import * as jobListActions from './jobList/actions'
import * as loginActions from './login/actions'
import * as passwordChangeActions from './passwordChange/actions'
import * as passwordRecoverActions from './passwordRecover/actions'
import * as passwordResetActions from './passwordReset/actions'
import * as registerActions from './register/actions'
import * as userDataActions from './userData/actions'
import * as companyActions from './company/actions'
import * as couponActions from './coupon/actions'
import * as gatekeeperActions from './gatekeeper/actions'

export const actions = {
  ...applicationListActions,
  ...applicationActions,
  ...assessmentActions,
  ...authorizationActions,
  ...companyActions,
  ...couponActions,
  ...courseActions,
  ...formActions,
  ...jobActions,
  ...jobDetailActions,
  ...jobListActions,
  ...loginActions,
  ...passwordChangeActions,
  ...passwordRecoverActions,
  ...passwordResetActions,
  ...registerActions,
  ...userDataActions,
  ...gatekeeperActions
}
