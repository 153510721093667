import * as types from '../../actions/register/types'

const defaultState = {
  isLoading: false,
  data: {},
  error: null,
  success: false
}

const loadRegister = (state) => ({
  ...state,
  ...defaultState,
  isLoading: true
})

const successRegister = (state, payload) => ({
  ...state,
  data: payload.data,
  error: null,
  isLoading: false,
  success: true
})

const failRegister = (state, payload) => ({
  ...state,
  data: {},
  error: payload.error,
  isLoading: false,
  success: false
})

const registerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.REGISTER_LOAD:
      return loadRegister(state)

    case types.REGISTER_SUCCESS:
      return successRegister(state, action.payload)

    case types.REGISTER_FAILED:
      return failRegister(state, action.payload)

    default:
      return state
  }
}

export default registerReducer
