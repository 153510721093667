import React from 'react'
import { connect } from 'react-redux'
import './ApplicationModules.sass'
import AbstractApplicationContent from './AbstractApplicationContent'
import DynamicComponents from './DynamicComponents'
import { CourseSuggestion } from '@mindlab-org/component-library'
class VojoApplicationContent extends AbstractApplicationContent {
  renderCourseSuggestion = () => {
    const { courses } = this.props.course.data

    if (courses && courses.length > 0) {
      return <div className="DevolutiveCourseSuggestion">
        <CourseSuggestion {...{ courses }}/>
      </div>
    }
  }

  chooseContent = () => {
    const { gatekeeper } = this.props
    const { application } = this.props.application.application.data

    const applicationCompleted = application.currentStatus === 'READY'

    if (!gatekeeper.approved) return this.renderGatekeeperFeedback()

    if (applicationCompleted) {
      const jobRoleId = application.jobRole.id

      return <>
        <DynamicComponents {...{ jobRoleId }} />
        {this.renderCourseSuggestion()}
      </>
    }

    return this.renderModules()
  }

  render () {
    return (
      <>
        {this.renderBanner()}
        {this.chooseContent()}
      </>
    )
  }
}

const mapStateToProps = state => ({
  application: state.application,
  gatekeeper: state.gatekeeper,
  course: state.course
})

export default connect(mapStateToProps)(VojoApplicationContent)
